import React from 'react'
import ViewCartPage from '../containers/Cart/ViewCartPage'  
import Searchbar from '../containers/Searchbar'   
 
const ViewCart = () => {
    return ( 
        <>
            <Searchbar />
            <ViewCartPage/>  
        </>
    )
}

export default ViewCart
