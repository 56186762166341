import React from 'react'
import { useParams } from 'react-router';
import Homepage from '../containers/Home/HomePage'  
import Searchbar from '../containers/Searchbar'   
 
const Home = () => {
    let params:any = useParams();
    return ( 
        <>
            <Searchbar /> 
            <Homepage market={params.market}/>  
        </>
    )
}

export default Home
