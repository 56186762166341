import * as React from 'react';
import classnames from 'classnames'

type Props = {
  children: React.ReactNode,
  weight?: 'default' | 'bold',
  type?: 'default' | 'darkgreen' | 'green'  | 'white',
  margin?: 'default' | 'none' | 'top' | 'bottom' | 'center',
  align?: 'default' | 'center',
  transform?: 'default' | 'uppercase',
  font?: 'source-sans-pro',
  size?: 'xl' | '2xl'
}

let getStyle = (type: Props['type']) => {
  switch (type) { 
    case 'white':
      return 'tw-text-white';
    case 'green':
      return 'tw-text-themegreen-200'
    case 'darkgreen':
      return 'tw-text-themegreen-400'; 
    default:
      return "tw-text-themeblack-100";
  }
}

let getWeight = (weight: Props['weight']) => {
  switch (weight) {
    case 'bold':
      return 'tw-font-bold';
    default:
      return "tw-font-semibold";
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'center':
      return 'tw-m-auto';
    case 'bottom':
      return 'tw-mb-10';
    case 'top':
      return 'tw-mt-2';
    case 'none':
      return 'tw-m-0';
    default:
      return 'tw-mb-2';
  }
}

let getAlign = (align: Props['align']) => {
  switch (align) {
    case 'center':
      return 'tw-text-center';
    default:
      return 'tw-text-left';
  }
}

let getFont = (font: Props['font']) => {
  switch (font) { 
      case 'source-sans-pro':
          return 'tw-font-source-sans-pro'; 
      default:
          return 'tw-font-roboto';
  }
} 


let getTransform = (transform: Props['transform']) => {
  switch (transform) {
    case 'uppercase':
      return 'tw-uppercase';
    default:
      return 'tw-normal-case';
  }
}

let getTextSize = (size: Props['size']) => {
  switch (size) { 
    case 'xl':
      return 'tw-text-xl';
    case '2xl':
      return 'tw-text-2xl';
    default:
      return 'tw-text-4xl';
  }
}

export const H1Modal = ({type, weight, margin, size, children }: Props) => {
  return <div className="tw-flex tw-justify-center tw-mx-auto tw-h-36 tw-w-full tw-absolute">
          <h1 className={classnames("tw-uppercase tw-text-shadow tw-z-50 banner-title",
            getStyle(type), getWeight(weight), getMargin(margin), getTextSize(size)
            )}>{children}
          </h1>
        </div>
}


export const H1 = ({weight, transform, type, margin, font, children }: Props) => {
  return <h1 className={classnames("tw-text-3xl",
          getStyle(type), getWeight(weight), getMargin(margin), getTransform(transform), 
          getFont(font)
            )} >{children}
          </h1>
}

export const H2 = ({weight, transform, type, margin, font, children }: Props) => {
  return <h2 className={classnames("tw-text-2xl tw-tracking-normal",
          getStyle(type), getWeight(weight), getMargin(margin), getTransform(transform),
          getFont(font)
            )} >{children}
          </h2>
}

export const H3 = ({weight, transform, type, font, margin, children }: Props) => {
  return <h3 className={classnames("tw-text-xl",
          getStyle(type), getWeight(weight), getMargin(margin), getTransform(transform),
          getFont(font)
            )} >{children}
          </h3>
}

export const H4 = ({weight, transform, type, margin, children }: Props) => {
  return <h3 className={classnames("tw-text-lg",
          getStyle(type), getWeight(weight), getMargin(margin), getTransform(transform)
            )} >{children}
          </h3>
}

export const H1Banner = ({align, size, children }: Props) => {
  return  <div className="tw-absolute tw-flex tw-h-36 tw-w-full">
            <h1 className={classnames("tw-text-white tw-align-middle tw-uppercase tw-m-auto text-shadow tw-font-bold",
              getAlign(align), getTextSize(size)
            )}>
              {children}
            </h1> 
          </div>
}