import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLProps<HTMLInputElement> & {
  children?: React.ReactNode,
  outline?: 'default' | 'grey' | 'green',
  width?: 'default' | 'eight', 
  color?: 'default' | 'grey' | 'white',
  border?: 'bottom'
} 

let getOutline = (outline: Props['outline']) => {
  switch (outline) { 
    case 'green':
      return 'focus:tw-outline-none tw-border-themegreen-300 focus:tw-border-themegreen-300'
    case 'grey':
      return 'focus:tw-outline-none tw-border-themegrey-100 focus:tw-border-themegreen-400'; 
    default:
      return 'focus:tw-outline-none tw-border-themegreen-200 focus:tw-border-themegreen-400';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) { 
    case 'bottom':
      return 'tw-border-b '; 
    default:
      return 'tw-border ';
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) { 
    case 'eight':
      return 'tw-w-8' 
    default:
      return 'tw-w-full';
  }
}

let getTextColor = (color: Props['color']) => {
  switch (color) { 
    case 'white':
      return 'tw-text-white';
    case 'grey':
      return 'tw-text-themegrey-100' 
    default:
      return 'tw-text-themeblack-100';
  }
}

export const TrackOrderInput = ({outline, width, color, children, ...props} : Props) => (
  <div className="tw-relative">
    <input className={classnames("tw-pl-2.5 tw-pr-10 tw-py-2 tw-rounded-md",
      getOutline(outline), getWidth(width), getTextColor(color))}
      {...props}/>
      {children}
  </div>
);

export const Input = ({outline, width, color, children, border, ...props} : Props) => (  
  <input className={classnames("tw-appearance-none tw-block tw-rounded tw-p-2 tw-leading-tight ",
    getOutline(outline), getWidth(width), getTextColor(color), getBorder(border))}
    {...props}/> 
);

export const QuantityInput = ({outline, width, color, children, ...props} : Props) => (  
  <input className={classnames("tw-text-center tw-mx-1 tw-bg-themegreen-300 tw-font-bold",
    getOutline(outline), getWidth(width), getTextColor(color))}
    {...props}/> 
);
 
export const IconInput = ({outline, width, color, children, ...props} : Props) => (  
  <div className="tw-flex tw-relative">
    <input className={classnames("tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-py-2 tw-pl-9 tw-pr-2",
    getOutline(outline), getWidth(width), getTextColor(color))}
    {...props}/> 
    {children}
    </div>
); 