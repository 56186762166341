import { useState, useEffect } from 'react' 
import { BoxContainer, GridContainer} from '../components/common/Container'  
import { H2, H3 } from '../components/common/Heading' 
import { Span } from '../components/common/Span'     
import { Button } from '../components/common/Button' 
import { useHistory } from 'react-router-dom'
import { FaCheckCircle } from "react-icons/fa";   

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store'
import { getOrderDetails } from '../network/product'
import Loading from '../components/Loading'

let default_img = 'https://static.jnb.ph/mommymili/product.jpg'

export default ({order_id, image = default_img, ...props}:any) => { 
 
    const history = useHistory();

    const market: any = useSelector(
        (state: RootState) => state.cart.market
    );

    const handleContinue = () => {   
        history.push('/market/'+(market.name ? market.name.toLowerCase() : '')); 
    }  
    
    const [data, setData] = useState({} as any); 
    const [item, setItem] = useState([]); 
    const [loading, setLoading] = useState(true);
    const fetchData = async() => {  
        let {order, order_items} : any = await getOrderDetails(order_id);  
         
        setData(order); 
        setItem(order_items); 
        setLoading(false) 
    } 
 
    useEffect(() => {
        fetchData();
    }, []);
 
    return (<>   
    {loading ?  <> 
      <Loading /> 
    </>:<> 
    <GridContainer> 
        <div className='tw-bg-themegreen-300 tw-bg-opacity-10 tw-py-20 tw-px-16 tw-mt-40'>
            <div className="tw-flex tw-mb-10">
                <FaCheckCircle className="check"/> 
                <div>
                    <Span>Order #{data?.id}</Span>
                    <H2>Thank You For Your Order</H2> 
                </div>
            </div>  

            {item.map((items:any) => (<> 
                <div className='tw-flex tw-justify-between tw-mb-5'>
                    <div className="tw-flex" key={items.id}>
                        <div>
                            <img src={items.image ? items.image : default_img} alt="" className="cart-image-1"/>
                        </div>
                        <div className='tw-my-auto tw-px-5'>
                            <p><Span weight="semibold">{items.name} {items.unit}</Span></p>
                            <p><Span weight="semibold">x {items.quantity}</Span></p>
                        </div>
                    </div>
                    <div className="tw-my-auto">  
                        <Span weight="medium" size='base'>{`₱${parseFloat(items.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                    </div>  
                </div> 
                </>))} 

            <div className='tw-flex tw-justify-between tw-py-4 tw-border-t-2 tw-border-themegrey-100 tw-border-opacity-30'>
                <Span>Subtotal</Span>
                <Span weight="medium" size='base'>{`₱${parseFloat(data?.sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
            </div>

            <div className='tw-flex tw-justify-between tw-py-4 tw-border-t-2 tw-border-themegrey-100 tw-border-opacity-30'>
                <Span>Pabili Fee</Span>
                <Span weight="medium" size='base'>{`₱${parseFloat(data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
            </div>

            <div className='tw-flex tw-justify-between tw-py-4 tw-border-t-2 tw-border-themegrey-100 tw-border-opacity-30'>
                <div>
                    <Span size='lg'>Total</Span>
                </div>
                <div> 
                    <Span size='2xl'> {`₱${parseFloat(data?.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                </div>
            </div>
        </div>

        <div className='tw-py-10 tw-px-16 lg:tw-pt-52 md:tw-pt-0'>  
            <BoxContainer width="full" margin="my">
                <p><H3>Your order is confirmed</H3></p>
                <Span padding='bottom'>We've accepted your order, and getting it ready!</Span>
            </BoxContainer>

            <BoxContainer width="full" margin="my">  
                <p><H3>Customer Information</H3></p> 

                <GridContainer>
                    <div>
                        <p><Span weight="semibold" size='base'>Shipping Address</Span></p>
                        <p><Span>{data?.first_name} {data?.last_name}</Span></p>
                        <p><Span>{data?.address_unit} {data?.address_title}</Span></p>
                        <p><Span>{data?.phone}</Span></p>
                    </div>
                    <div>
                        <p><Span weight="semibold" size='base'>Payment method</Span></p> 
                        <p><Span>Cash on Delivery(COD) - {`₱${parseFloat(data?.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span></p>
                    </div>
                </GridContainer> 
            </BoxContainer>

            <div className="tw-my-5"> 
                <Button type="green" border="green" width="none" padding="primary"
                onClick={handleContinue}>
                    Continue Shopping
                </Button> 
            </div>
        </div>
    </GridContainer>  
    </>}
    </>);
}