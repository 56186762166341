import { createModel } from "@rematch/core";
import { RootModel } from "./index";;

type Item = {
    id: number;
    title: string; 
    merchant: string;
    quantity: number;
    image: string;
    price: number;
    subtotal?: number; 
}

type Location = {
  lat: number;
  lng: number;
  address: string; 
}

type Market = {
  id: number,
  name: number
}

type Customer = { 
  first_name: string,
  last_name: string, 
  unit: string,  
  phone: string;
  email: string, 
  note: string,  
} 

type OrderType = {
  first_name?: string,
  last_name?: string, 
  address?: {
    lat?: number,
    lng?: number,
    unit?: string
  }, 
  phone?: string,
  email?: string,
  note?: string,
  items?: [
      {
        id?: number,
        name?: string,
        merchant?: string,
        quantity?: number,
        price?: number,
        total?: number
      }
  ],
  total?: number
}


const initialState = {
  items: [] as any,
  customer: {},
  market: {} as Market,
  total: 0,
  location: {
    lat: 0,
    lng: 0,
    address: '',
  },
  orders:[] as any,
  token: {}
}

export default createModel<RootModel>()({
  state: initialState, 
  reducers: {
    // handle state changes with pure functions
    setCustomer(state, payload: Customer) {
      return {...state, customer: payload};
    },
    setLocation(state, payload: Location) {
      return {...state, location: payload};
    },
    updateOrders(state, payload: Array<OrderType>) {
      return {
        ...state,
        orders: payload
      };
    },
    updateMarket(state, payload: Market) {
      return {
        ...state,
        market: payload
      };
    },
    updateItems(state, payload: Array<Item>) {

      let total = 0;
      for (const key in payload) {
        let subtotal = payload[key].price * payload[key].quantity;
        payload[key].subtotal = subtotal
        total = total + subtotal;
      } 

      return {
        ...state,
        total,
        items: payload
      };
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async addItem(payload: Item, state) {

        let items = state.cart.items;

        let currentItems = state.cart.items;

        let exist = false;

        for (const key in state.cart.items) {
            let obj = currentItems[key];
 
            if(payload.id === obj.id){
              exist = true;
            }
        }

        if(!exist){
          dispatch.cart.updateItems([...state.cart.items, payload]);
        }

        dispatch.ui.success('Item Added to Cart')

    },    
    async setMarket(payload: Market, state) {

      if(payload.id != state.cart.market?.id){
        dispatch.cart.cleanCart(true);
      }

      dispatch.cart.updateMarket(payload)

  },
    async cleanCart(payload:boolean, state) {
      dispatch.cart.updateItems([]);
    },
    async updateItem(payload: Item, state) {

        let currentItems = state.cart.items;
        
       //find the key of the item
       for (const key in state.cart.items) {
           let obj = currentItems[key];

           if(payload.id === obj.id){
            currentItems[key] = payload
           }
       }

      //find the key of the item
      dispatch.cart.updateItems([...currentItems]);
    },
    async removeItem(payload: Item, state) {

        let currentItems = state.cart.items;
        
       //find the key of the item
       for (const key in state.cart.items) {
           let obj = currentItems[key];

           if(payload.id === obj.id){
            currentItems.splice(key, 1)
           }
       }

      //find the key of the item
      dispatch.cart.updateItems([...currentItems]);
    },
    async updateAddress(payload: Location, state){
      dispatch.cart.setLocation(payload);
    },
    async clearAddress(payload: Location, state){
      dispatch.cart.updateAddress(payload);
    }, 
    async updateCustomer(payload: Customer, state){
      dispatch.cart.setCustomer(payload);
    },
    async order(payload: OrderType, state) {  
      dispatch.cart.updateOrders([payload]);
      
      dispatch.cart.cleanCart(true); 

    },
  }),
});