import * as React from 'react';
import classnames from 'classnames';
import { width } from '@mui/system';

type Props = React.HTMLProps<HTMLButtonElement> & { 
  type?: 'default'  | 'green' | 'darkgreen' | 'none',  
  textcolor?: 'default' | 'black' | 'green' | 'grey',  
  padding?: 'default' | 'none' | 'primary' | 'secondary' | '1.5' | 'p-2',
  border?: 'default' | 'green' | 'darkgreen',
  background?: 'default' | 'cod',
  radius?: 'default' | 'rounded'
  width?: 'default' | 'none'
  transform?: 'default' | 'uppercase',
  weight?: 'default' | 'medium' | 'semibold' | 'bold',
  justify?: 'start' | 'end',
  margin?: 'm-5' | 'mt-5' | 'my-5'
}

let getBorder = (border: Props['border']) => {
  switch (border) {  
    case 'darkgreen':
      return 'tw-border tw-border-themegreen-400'
    case 'green':
      return 'tw-border tw-border-themegreen-300'
    default:
      return 'tw-border tw-border-themegrey-100'
  }
}

let getTypeClass = (type: Props['type']) => {
  switch (type) {
    case 'none':
      return '' 
    case 'darkgreen':
      return 'tw-bg-themegreen-400 hover:tw-bg-themegreen-300 hover:tw-border-themegreen-300'
    case 'green':
      return 'tw-bg-themegreen-300 hover:tw-bg-themegreen-400 hover:tw-border-themegreen-400'
    default:
      return 'tw-bg-white'
  }
}

let getTextColor = (textcolor: Props['textcolor']) => {
  switch (textcolor) { 
    case 'green':
      return 'tw-text-themegreen-300 hover:tw-text-themegreen-400'
    case 'black':
      return 'tw-text-black hover:tw-text-themegreen-400'
    case 'grey':
      return 'tw-text-themegrey-200 hover:tw-text-themegreen-300'
    default:
      return 'tw-text-white'
  }
}  

let getPadding = (padding: Props['padding']) => {
  switch (padding) { 
    case 'secondary':
      return 'tw-py-4';
    case 'primary':
      return 'tw-px-8 tw-py-1';
    case '1.5':
      return 'tw-py-1.5'; 
    case 'p-2':
      return 'tw-p-2';
    case 'none':
      return 'tw-p-0';
    default:
      return 'tw-py-2';
  }
}

let getRadius = (radius: Props['radius']) => {
  switch (radius) {  
    case 'rounded':
      return 'tw-rounded';
    default:
      return 'tw-rounded-2xl';
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) {  
    case 'none':
      return '';
    default:
      return 'tw-w-full';
  }
}

let getTransform = (transform: Props['transform']) => {
  switch (transform) {  
    case 'uppercase':
      return 'tw-uppercase';
    default:
      return 'tw-normal-case';
  }
}

let getWeight = (weight: Props['weight']) => {
  switch (weight) {  
    case 'bold':
      return 'tw-font-bold tw-text-3xl';
    case 'semibold':
      return 'tw-font-semibold tw-text-2xl'; 
    case 'medium':
      return 'tw-font-medium tw-text-lg';
    default:
      return 'tw-font-normal tw-text-base';
  }
}

let getJustify = (justify: Props['justify']) => {
  switch (justify) {    
    case 'end':
      return 'tw-justify-end'
    case 'start':
      return 'tw-justify-start';
    default:
      return 'tw-justify-center';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {  
    case 'my-5':
      return 'tw-my-5'     
    case 'm-5':
      return 'tw-m-5'  
    case 'mt-5':
      return 'tw-mt-5' 
    default:
      return 'tw-mt-2';
  }
} 


export const ModalCloseButton = ({ type, padding, textcolor, children, ...props}: Props) => {
  return <div className={classnames("tw-absolute tw-w-full")}>
    <button className={classnames("tw-float-right tw-leading-none tw-font-semibold tw-outline-none tw-m-auto tw-ml-auto tw-py-1 tw-px-3",
    getTextColor(textcolor)
    )} {...props } >{children}
  </button></div>
}

export const CartButton = ({ type, textcolor, transform, radius, padding, justify, children, ...props}: Props) => {
  return <button className={classnames("tw-w-full tw-text-normal tw-font-semibold tw-whitespace-nowrap tw-mt-2 tw-inline-flex",
    getTypeClass(type), getTextColor(textcolor), getPadding(padding), getRadius(radius), getTransform(transform), getJustify(justify)
    )} {...props } >{children}
  </button>
}

export const Button = ({ type, radius, textcolor, transform, border, padding, width, weight, margin, justify, children, ...props}: Props) => {
  return <button className={classnames("tw-whitespace-nowrap tw-inline-flex",  getWeight(weight), getJustify(justify),
    getTypeClass(type), getTextColor(textcolor), getPadding(padding), getBorder(border), getRadius(radius), getWidth(width), 
    getTransform(transform), getMargin(margin)
    )} {...props } >{children}
  </button>
}  

export const QuantityButton = ({ type, weight, children, ...props}: Props) => {
  return <button className={classnames("tw-mx-auto", getTypeClass(type), getWeight(weight)
     )} {...props } >{children}
  </button>
} 
  