import React, { useState } from 'react' 
import { useParams } from "react-router-dom"; 

import MerchantProduct from '../containers/MerchantProduct';
import Searchbar from '../containers/Searchbar' 
 
const Merchant = () => {

    let params:any = useParams();

    console.log("params", params)

    return ( 
        <>
            <Searchbar />
            <MerchantProduct merchant={params.id}/>   
        </>
    )
}

export default Merchant
