import * as React from 'react';
import classnames from 'classnames'

type Props = {
  children: React.ReactNode, 
  type?: 'default' | 'darkgreen' | 'green' | 'lightgreen' | 'white' | 'grey' | 'none',
  weight?: 'default' | 'medium' | 'semibold' | 'bold' | 'xl' | '2xl',
  align?: 'default' | 'center',
  padding?: 'default' | 'normal' | 'primary' | 'secondary' | 'py' | 'bottom',
  transform?: 'default' | 'uppercase',
  margin?: 'default' | 'auto' | 'my',
  visibility?: 'default' | 'hidden'
  bg?: 'green',
  size?: 'base' | 'lg' | '2xl' | '4xl',
  whitespace?: 'nowrap',
  font?: 'roboto', 
  flex?: 'inline-flex'
}

let getStyle = (type: Props['type']) => {
    switch (type) { 
      case 'grey':
        return 'tw-text-themegrey-300';
      case 'white':
        return 'tw-text-white';
      case 'lightgreen':
        return 'tw-text-themegreen-300'
      case 'green':
        return 'tw-text-themegreen-200'
      case 'darkgreen':
        return 'tw-text-themegreen-400';
      case 'none':
        return ''; 
      default:
        return "tw-text-themeblack-100";
    }
}

let getWeight = (weight: Props['weight']) => {
  switch (weight) {
    case '2xl':
      return 'tw-font-bold'; 
    case 'xl':
      return 'tw-font-semibold'; 
    case 'bold':
      return 'tw-font-bold'; 
    case 'semibold':
      return 'tw-font-semibold'; 
    case 'medium':
      return 'tw-font-medium'; 
    default:
      return 'tw-font-normal';
  }
}

let getSize = (size: Props['size']) => {
  switch (size) {
    case '4xl':
      return 'tw-text-4xl'; 
    case '2xl':
      return 'tw-text-2xl'; 
    case 'lg':
      return 'tw-text-lg'; 
    case 'base':
      return 'tw-text-base'; 
    default:
      return 'tw-text-sm';
  }
}

let getAlign = (align: Props['align']) => {
  switch (align) { 
      case 'center':
          return 'tw-text-center'; 
      default:
          return 'tw-text-left';
  }
}

let getPadding= (padding: Props['padding']) => {
  switch (padding) { 
    case 'bottom':
      return 'tw-pb-4'; 
    case 'py':
      return 'tw-py-4'; 
    case 'secondary':
      return 'tw-px-2';  
    case 'primary':
      return 'tw-px-1'; 
    case 'normal':
      return 'tw-px-0.5'; 
    default:
      return '';
  }
} 

let getTransform = (transform: Props['transform']) => {
  switch (transform) {
    case 'uppercase':
      return 'tw-uppercase';
    default:
      return 'tw-normal-case';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'my':
      return 'tw-my-4'
    case 'auto':
      return 'tw-m-auto';
    default:
      return 'tw-my-auto';
  }
}

let getVisibility = (visibility: Props['visibility']) => {
  switch (visibility) {
    case 'hidden':
      return 'tw-invisible md:tw-visible lg:tw-visible';
    default:
      return 'tw-visible';
  }
}

let getBg = (bg: Props['bg']) => {
  switch (bg) { 
      case 'green':
          return 'tw-bg-themegreen-400'; 
      default:
          return '';
  }
}

let getWhitespace = (whitespace: Props['whitespace']) => {
  switch (whitespace) { 
      case 'nowrap':
          return 'tw-whitespace-nowrap md:tw-whitespace-wrap'; 
      default:
          return 'tw-whitespace-normal';
  }
}

let getFont = (font: Props['font']) => {
  switch (font) { 
      case 'roboto':
          return 'tw-font-roboto'; 
      default:
          return 'tw-font-source-sans-pro';
  }
}  

let getFlex = (flex: Props['flex']) => {
  switch (flex) { 
      case 'inline-flex':
          return 'inline-flex'; 
      default:
          return '';
  }
}  

export const Span = 
  ({type, align, padding, weight, transform, margin, size, visibility, bg, whitespace, font, flex, children }: Props) => { 
  return <span className={classnames( getMargin(margin), getVisibility(visibility), getBg(bg), getSize(size),
          getStyle(type), getWeight(weight), getAlign(align), getPadding(padding), getTransform(transform),
          getWhitespace(whitespace), getFont(font), getFlex(flex))} >{children}
          </span>
} 
// inline-flex items-center h-full text-base px-4 py-2