import * as React from 'react';
import classnames from 'classnames' 

type Props = {
  children: React.ReactNode,
  type?: 'default' | 'darkgreen' | 'green' | 'white' | 'black',
  padding?: 'default' | 'primary' | 'none',
  margin?: 'default' | 'top'
}

let getStyle = (type: Props['type']) => {
  switch (type) { 
    case 'black':
      return 'tw-text-themeblack-100'; 
    case 'white':
      return 'tw-text-white';
    case 'green':
      return 'tw-text-themegreen-200'
    case 'darkgreen':
      return 'tw-text-themegreen-400'; 
    default:
      return "tw-text-gray-400 ";
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) { 
    case 'none':
      return 'tw-p-0'; 
    case 'primary':
      return 'tw-px-0.5'
    default:
      return "tw-pb-3";
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {   
    case 'top':
      return 'tw-mt-1'
    default:
      return "tw-m-0";
  }
}


export const Label = ({ margin, type, padding, children }: Props) => { 
    return <label className={ classnames("tw-text-sm", getStyle(type), getPadding(padding), getMargin(margin)
            )} >{children}
           </label>
  }