import { useState, useEffect } from 'react'
import { SubContainer, BannerContainer, GridContainer } from '../components/common/Container'  
import { H1Banner } from '../components/common/Heading' 
import { Button } from '../components/common/Button' 
import { Span } from '../components/common/Span' 
import Loading from '../components/Loading'
import { Link } from 'react-router-dom' 

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import ProductTab from '../components/ProductTab'  

import { useSelector } from 'react-redux'; 
import { RootState } from '../store'; 

import { getProductsByCategory, getCategory } from '../network/product'   
import { FaSortAlphaDownAlt, FaSortAlphaUp, FaSortNumericDownAlt, FaSortNumericUp } from "react-icons/fa";

let default_img = 'https://static.jnb.ph/mommymili/product.jpg'

let groupBySubCat = (data:any) => {

    let grouped = {} 

    for (const iterator of data) {
      // Category

      if(!iterator['subcategory_name']){
        continue;
      }
      if(!grouped[iterator['subcategory_name']]){
        grouped[iterator['subcategory_name']] = []
      }
      grouped[iterator['subcategory_name']].push(iterator); 
    }
    return grouped;
}


export default ({category, image=default_img, ...props}:any) => {  
  
  const [loading, setLoading] = useState(true);
  const [categories, setCategoryDetails] = useState([]);
  const [subcategories, setSubcategories] = useState(['']); 
  const [products, setProduct] = useState([] as any); 
  const [groupedProducts, setGroupedProducts] = useState({}); 
  const [details, setDetails] = useState({
    id: '',
    name: '',
    banner: '',
    cover: '',
  }); 
  
  const market: any = useSelector(
      (state: RootState) => state.cart.market
  ); 
  
  const fetchData = async() => {  
    let result = await getProductsByCategory(category, market.name); 
    let res = await getCategory();
    
    if(!result){
      return;
    }

    let {details, products}:any = result;
    let {data} : any = res;
    
    setDetails(details)
    setProduct(products)
    let groupedProducts = groupBySubCat(products)
    setSubcategories(Object.keys(groupedProducts))
    setCategoryDetails(data); 
    setLoading(false) 
  }  

  useEffect(() => {
      window.scrollTo(0, 0) 
      fetchData();  
  }, [category]);
  
  useEffect(() => {
    let groupedProducts = groupBySubCat(products)
    setGroupedProducts(groupedProducts)
  }, [products]);

  const [name, setName] = useState(false) 
   
  useEffect(() => {
      if(name){
        const dataArray = [ ...products]
        setProduct(dataArray.sort((a,b) => a.title.localeCompare(b.title))); 
      }else{
        const dataArray = [ ...products]
        setProduct(dataArray.sort((a,b) => b.title.localeCompare(a.title))); 
      }
  }, [name])

  const handleName = ()=> {
    setName(!name);
  }
   
  const [prices, setPrice] = useState(false)  
    useEffect(() => {
        if(prices){
          const dataArray = [ ...products]
          setProduct(dataArray.sort((a,b) => (a.price - b.price))); 
        }else{
          const dataArray = [ ...products]
          setProduct(dataArray.sort((a,b) => (b.price - a.price))); 
        }
    }, [prices])

  const handlePrice = ()=> {
    setPrice(!prices);
  } 

  const [value,setValue]=useState(0)
  const handleChange = (event:any, newValue:any) => { setValue(newValue);};
  
  function TabPanel(props:any) {
    const { children, value, index, ...other } = props;
   
  return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
        )}
      </div>
    );
  }   

  return (<>   
  {loading ?  <> 
      <Loading /> 
    </>:<>  
    <SubContainer width='secondary' margin='top-44' flex='none'>
      <BannerContainer>
        <div className='banner-content'>
        <img src={details.banner} className='banner-image'/>
        </div>
        <H1Banner align='center'>{details.name}</H1Banner>
      </BannerContainer> 

      <div className='tw-pt-4 tw-pb-6'> 
        <Box sx={{ width: '100%' }}>
          <Tabs value={value} TabIndicatorProps={{style: {background:'#9fbf15'}}}
          onChange={handleChange} aria-label='disabled tabs example'> 
            <Tab label={<><div className='tw-flex'>
              <label className='tabtext'>All</label></div></>}/> 
              {console.log('product',subcategories)}
              {
                subcategories.map((name, key) => <Tab key={key} label={<><div className='tw-flex'>
                <label className='tabtext'>{name}</label> 
                </div>
                </>}/>
              )}
          </Tabs>  

          {products.length === 0 ? <></> : <>
            <div className='tw-text-right'>  
              <div className='tw-inline-flex'>
                <Button onClick={handleName} width='none' radius='rounded' padding='p-2' margin='m-5'>
                  <Span flex='inline-flex'>Sort by Alphabetical</Span>
                  <Span flex='inline-flex' size='base' padding='primary'>
                    {name  ? <FaSortAlphaUp/> : <FaSortAlphaDownAlt/>} 
                  </Span>
                </Button>

                <Button onClick={handlePrice} width='none' radius='rounded' padding='p-2' margin='my-5'>
                  <Span flex='inline-flex'>Sort by Price</Span>
                  <Span flex='inline-flex' size='base' padding='primary'>
                    {prices  ? <FaSortNumericUp/> : <FaSortNumericDownAlt/>} 
                  </Span>
                </Button> 
              </div> 
            </div>
          </>}

          <TabPanel value={value} index={0}>
            <ProductTab key={0} 
              products={products}   
            />
          </TabPanel> 
          {
            subcategories.map((name, id) => <TabPanel value={value} index={id+1}>
              <ProductTab key={id+1} products={groupedProducts[name]}/>
            </TabPanel>
            )
          }
        </Box> 
      </div>

        {/* Category footer */}
        <GridContainer>
          {
            categories.map((cat:any, index) => { 
            return <> 
              <Link to={`/category/${cat.id}`}> 
                  <div className='tw-relative tw-h-36'>
                    <div className='banner-content'>
                      <img src={cat.thumbnail_image ?? default_img} className='banner-image'/>
                    </div>
                    {/* <H1Banner align='center' size='xl'>{cat.name}</H1Banner> */}
                  </div> 
              </Link> 
            </>
            })
          } 
        </GridContainer> 

        </SubContainer>   
    </>}  
  </>);
} 
