import { useState, useEffect } from 'react'
import { SubContainer, BannerContainer, GridContainer } from '../components/common/Container'  
import { H1Banner } from '../components/common/Heading' 
import { Button } from '../components/common/Button' 
import { Span } from '../components/common/Span' 
import Loading from '../components/Loading'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'; 
import ProductTab from '../components/ProductTab'
import { Link } from 'react-router-dom'  

import { getProductsByMerchant, getMerchant } from '../network/product'
import { FaSortAlphaDownAlt, FaSortAlphaUp, FaSortNumericDownAlt, FaSortNumericUp } from "react-icons/fa";

import StatusModal from '../components/StatusModal'
  
let default_img = 'https://static.jnb.ph/mommymili/product.jpg'

export default ({merchant, image=default_img, ...props}:any) => { 

  const [loading, setLoading] = useState(true);
  
  // Modal
  const [modalStatus, setStatusModal] = useState(false); 
      
  const [products, setProduct] = useState([] as any); 
  const [groupedProducts, setGroupedProducts] = useState({}); 
  const [details, setDetails] = useState({
    id: "",
    name: "",
    banner_image: "",
    thumbnail_image: "",
  });  

  const [merch, setMerchant] = useState([]) 
  const fetchData = async() => { 
    
    let result = await getProductsByMerchant(merchant);
    let res = await getMerchant();
    
    if(!result){
      return;
    }

    let {details, products}:any = result;
    let {data} : any = res;
 
    console.log('products', groupedProducts) 
    setDetails(details)
    setProduct(products) 
    setMerchant(data) 
    setLoading(false) 
  }

  useEffect(() => { 
    window.scrollTo(0, 0) 
    fetchData();
  }, [merchant]);
 
  const [name, setName] = useState(false) 
   
  useEffect(() => {
      if(name){
        const dataArray = [ ...products]
        setProduct(dataArray.sort((a,b) => a.title.localeCompare(b.title))); 
      }else{
        const dataArray = [ ...products]
        setProduct(dataArray.sort((a,b) => b.title.localeCompare(a.title))); 
      }
  }, [name])

  const handleName = ()=> {
    setName(!name);
  }
   
  const [prices, setPrice] = useState(false)  
    useEffect(() => {
        if(prices){
          const dataArray = [ ...products]
          setProduct(dataArray.sort((a,b) => (a.price - b.price))); 
        }else{
          const dataArray = [ ...products]
          setProduct(dataArray.sort((a,b) => (b.price - a.price))); 
        }
    }, [prices])

  const handlePrice = ()=> {
    setPrice(!prices);
  } 
  
  const [value,setValue]=useState(0)
  const handleChange = (event:any, newValue:any) => { setValue(newValue);};
  
  function TabPanel(props:any) {
    const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
    );
  }  

  return (<>   
  {loading ?  <> 
    <Loading /> 
    </>:<>  
    <SubContainer width="secondary" margin='top-44'>  
        <BannerContainer>
                <div className="banner-content">
                <img src={details.banner_image} className="banner-image"/>
                </div>
                <H1Banner>{details.name}</H1Banner>
          </BannerContainer>

          <div className="tw-pt-4 tw-pb-6"> 
          <Box sx={{ width: '100%' }}>
          <Tabs value={value} TabIndicatorProps={{style: {background:'#9fbf15'}}}
          onChange={handleChange} aria-label="disabled tabs example"> 
              <Tab label={<><div className="tw-flex">
                <label className="tabtext">All</label>
                {/* <img src={details.thumbnail_image} className="tabIcon" /> */}
              </div> </>} />
          </Tabs>

          {products.length === 0 ? <></> : <>
            <div className='tw-text-right'>  
              <div className='tw-inline-flex'>
                <Button onClick={handleName} width='none' radius='rounded' padding='p-2' margin='m-5'>
                  <Span flex='inline-flex'>Sort by Alphabetical</Span>
                  <Span flex='inline-flex' size='base' padding='primary'>
                    {name  ? <FaSortAlphaUp/> : <FaSortAlphaDownAlt/>} 
                  </Span>
                </Button>

                <Button onClick={handlePrice} width='none' radius='rounded' padding='p-2' margin='my-5'>
                  <Span flex='inline-flex'>Sort by Price</Span>
                  <Span flex='inline-flex' size='base' padding='primary'>
                    {prices  ? <FaSortNumericUp/> : <FaSortNumericDownAlt/>} 
                  </Span>
                </Button> 
              </div> 
            </div>
          </>}

          <TabPanel value={value} index={0}>
          <ProductTab key={0} 
              products={products}  
              setStatusModal={setStatusModal}  
            />
            </TabPanel> 
          </Box>   
          </div> 
          
          <GridContainer>
            {
              merch.map((merchan:any) => { 
              return <> 
                <Link to={`/merchant/${merchan.id}`}>   
                    <div className="tw-relative tw-h-36">
                      <div className="banner-content">
                        <img src={merchan.thumbnail_image ?? default_img} className="banner-image"/> 
                      </div>
                      {/* <H1Banner align='center' size='xl'>{merchan.name}</H1Banner> */}
                    </div>  
                </Link> 
              </>
              })
            } 
          </GridContainer>
    </SubContainer>
    
    {modalStatus && <StatusModal/>} 
    </>}  
  </>);
} 
