import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'

import { Provider, connect } from "react-redux";
import { store } from "./store";


import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

let element:any = document.getElementById('mommymili');

const persistor = getPersistor()


const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AlertProvider template={AlertTemplate} {...options}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AlertProvider>
    </PersistGate>
  </Provider>,
  element
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
