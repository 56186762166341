import React, { useState } from 'react'
import { TabContainer, QuantityContainer, SubContainer } from './common/Container'
import { CartButton, QuantityButton } from './common/Button'
import { QuantityInput } from './common/Input'
import { Span } from './common/Span'

import StarsRating from "./StarsRating"
import HeartRating from "./HeartRating"

import { BiShoppingBag } from 'react-icons/bi'

import { useSelector, useDispatch } from 'react-redux'
import { RootState, Dispatch } from '../store'

import LocationModal from '../components/LocationModal'

// import Product from './Product' 

let default_img = 'https://static.jnb.ph/mommymili/product.jpg'
 
export default ({products, handleAddItem, image=default_img, ...props}:any) => {  
    const cartItems: any = useSelector(
        (state: RootState) => state.cart.items
    );

    const location_address: any = useSelector(
        (state: RootState) => state.cart.location
      );
      

    const dispatch = useDispatch<Dispatch>();
 
    const [quantity, setQuantity] = useState(true);    

    // Cart   
    const handleAddProduct = (product) => { 
        product.quantity = 1;
        dispatch.cart.addItem(product);  
    };  

    const [count, setCount] = useState(0);    
    
    const updateItem = (product) => { 
        dispatch.cart.updateItem(product); 
    }  

    const removeItem = (product) => { 
        dispatch.cart.removeItem(product);  
        setQuantity(true)
    } 
    

    function handlePlus(key:any) {
        let item = products[key]
        item.quantity = item.quantity + 1;
        updateItem(item) 
    }

    function handleMinus(key:any) {
        let item = products[key]
        item.quantity = item.quantity - 1;
        if(item.quantity <= 0){
        item.quantity = 0;
        removeItem(item) 
        }else{
        updateItem(item)
        }
    }

    function handleRemoveItem(key:any, e:any) { 
        e.preventDefault(); 
        let item = cartItems[key]
        removeItem(item) 
    }  

    function handleQuantity(e) {
        e.preventDefault();  
        setCount(count)
    }  

    // Location Modal
    const [modalLocation, setLocationModal] = useState(false);
    const [location, setLocation] = useState(true);

    const handleLocation = () => { 
        setLocation(false)
        setLocationModal(false)
      }

    return <>
        {products.length === 0 && 
            <div className='tw-mt-5'>
                <Span size='lg'>
                    We're working on adding items here for you to choose from. Stay tuned!
                </Span>  
            </div> 
        }
        <TabContainer>   
        
        {products.map((product, key) => { 
            for (const key in cartItems) {
                let obj = cartItems[key];

                if(product.id === obj.id){
                    product.quantity= obj.quantity
                } 
            }

            let found = cartItems.some(el => el.id === product.id) 

            return <div className="card">
            <HeartRating />
            <img className="card-img-top tw-object-cover tw-h-40"   src={product.image ?? default_img} onError={(e:any)=>{e.target.onerror = null; e.target.src=default_img}} />
            <div className="card-block">  
                <label className="card-text" >
                <span className="product-name">{product.title}</span>
                </label>
                <label className="card-text">{product.description}</label> 
                <label className="card-text">{product.unit}</label>
                <StarsRating/>
                <h4 className="card-price">{`₱${parseFloat(product.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h4> 
                
                
                {/* No Address */}
                {location_address.address === "" && (
                <CartButton 
                    type="green" 
                    padding="1.5" 
                    onClick={() => setLocationModal(true)}
                > 
                    <BiShoppingBag className="card-bag"/>
                    Add to Cart
                </CartButton> 
                )}

                {/* With Address */}
                {location_address.address != "" && (<>   
                    { 
                        product.quantity >= 1 && <>
                        <QuantityContainer background="green" margin='mt-2' width='full' key={product.id} >
                            <QuantityButton type="none" weight="semibold" onClick={() => handleMinus(key)}> - </QuantityButton>  
                            <QuantityInput
                                outline="green" width="eight" color="white"
                                type="text" 
                                value={product.quantity}
                                onChange={handleQuantity}
                            /> 
                            <QuantityButton type="none" weight="semibold" onClick={() => handlePlus(key)} >+</QuantityButton> 
                        </QuantityContainer>    
                    </>}    
                    {!product.quantity &&
                        <CartButton 
                            type="green" 
                            padding="primary"
                            onClick={() => handleAddProduct(product)}
                            // onClick={() => {alert(`item added`)}}
                        > 
                            <div className='tw-flex'>
                                <BiShoppingBag className="tw-my-auto tw-mx-1"/>
                                Add to Cart
                            </div>
                        </CartButton>
                    }
                </>)} 

            </div>
        </div>
        })
    }

    {modalLocation && 
      <LocationModal 
        setLocationModal={setLocationModal} 
        handleLocation={handleLocation} 
      />
    }
    
    </TabContainer> 
    
    
    </>
}