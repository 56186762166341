import { useState } from 'react' 
import { ModalContainer } from './common/Container'
import { ModalCloseButton} from './common/Button' 
import { Button } from './common/Button' 
import { Span } from './common/Span' 
import { H2 } from './common/Heading'  
import { IoSearchOutline, IoClose } from "react-icons/io5";  
import { MdHome } from "react-icons/md";  
import MapContainer from './MapContainer'

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store'

type Props = {
    setLocationModal: Function,
    handleLocation: Function 
  }

export default ({setLocationModal, handleLocation, ...props}: Props) => {
   
    const location: any = useSelector(
        (state: RootState) => state.cart.location
    );    

  return (<>   
    <ModalContainer width='primary' position='center'>
            <div className="tw-flex tw-pt-1.5"> 
                <ModalCloseButton textcolor="green" onClick={() => setLocationModal(false)} > 
                    <IoClose className="tw-text-2xl"/> 
                </ModalCloseButton>    
                <div className='tw-flex tw-mx-auto tw-py-5'>
                    <MdHome className='icon-loc-modal '/> 
                    <Span weight='medium' size='lg'>Please enter your delivery location</Span>
                </div> 
            </div> 

        <div className='tw-mx-5'>
            <div className='tw-flex'>
                <Span size='base'>Select Address</Span>  
            </div> 

            <div className='tw-h-48'> 
                <MapContainer location={location}/> 
            </div>

            <div className='tw-my-5 tw-text-right'>
                <Button type="green" border="green" width="none" padding="primary" radius='rounded'
                onClick={() => handleLocation(false)}>
                    Done
                </Button> 
            </div>
        </div>
    </ModalContainer>
  <div className="tw-opacity-60 tw-fixed tw-inset-0 tw-z-40 tw-bg-black"></div>
  </>);
} 