import { useState, useEffect } from 'react' 
import { SubContainer, BannerPageContainer, BoxContainer} from '../components/common/Container'   
import { Button } from '../components/common/Button' 
import { useHistory } from 'react-router-dom'
import { H1, H2 } from '../components/common/Heading'      
import { Span } from '../components/common/Span'           
import { Label } from '../components/common/Label'  
import { Input } from '../components/common/Input'         
import { Textarea } from '../components/common/Textarea' 
import { RiArrowRightSLine } from "react-icons/ri";   
import { order } from "../network/auth"

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store' 

import { getHomeDetails } from '../network/product'

import * as Yup from 'yup'
import {useFormik } from 'formik'

const initialValues = {
    first_name: '',
    last_name:'', 
    unit: '', 
    phone: '',
    email: '',
    note: ''
  } 
  
  const onSubmit = values => {
    console.log('Form data', values)
  }
  
  const validationSchema = Yup.object({
    first_name: Yup.string().required('Required!'),
    last_name: Yup.string().required('Required!'), 
    unit: Yup.string().required('Required!'),  
    phone: Yup.string().max(12, "Invalid phone number!").required('Required!'),
    email: Yup.string().email('Invalid email format!').required('Required!'), 
  })

export default () => { 
    
    const dispatch = useDispatch<Dispatch>();
  
    const cartItems: any = useSelector(
        (state: RootState) => state.cart.items
    );    

    const total: any = useSelector(
        (state: RootState) => state.cart.total
    ); 
    
    const location_address: any = useSelector(
        (state: RootState) => state.cart.location
    );   
    
    const cartState: any = useSelector(
        (state: RootState) => state.cart
    );   


    const placeOrder = async (payload)  => {        
      let orderData = {
        market_id: cartState.market.id,
        market_name: cartState.market.name,
        
        first_name: payload.first_name,
        last_name: payload.last_name,
        address: {
          lat: cartState.location.lat,
          lng: cartState.location.lng,
          title: cartState.location.address,
          unit: payload.unit
        }, 
        phone: payload.phone,
        email: payload.email,
        note: payload.note,
        items: cartState.items,
        total: cartState.total
      } 

      let result = await order(orderData);

      if(result){
        dispatch.cart.order(result)
        history.push("/placeorder/"+result.orderNumber); 
      }  
    } 

    const history = useHistory();

      let handleCustomer = async (e:any) => {
        e.preventDefault(); 
        dispatch.cart.updateCustomer(formik.values);
        placeOrder(formik.values)
        console.log(formik.values) 

    }

    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })
    
    const market: any = useSelector(
        (state: RootState) => state.cart.market
    );
    
    const [data, setData] = useState({} as any);   

    const getAllDetails = async() => { 
        let {details } : any = await getHomeDetails(market.name ? market.name.toLowerCase() : '');

        dispatch.cart.setMarket(details)
        setData(details);   
    }

    useEffect(() => {
        getAllDetails();
    }, []); 
    
    return (<> 
        <BannerPageContainer background="cart">
            <H1 weight="bold" type="darkgreen">Checkout</H1>
            <div className="tw-flex">
                <a href="/" className="darkgreen">Home</a>
                <RiArrowRightSLine className="tw-text-base tw-mt-1 tw-text-themeblack-100"/>
                <Span>Checkout </Span>   
            </div>
        </BannerPageContainer>

        <SubContainer width="tertiary"> 
        
        
        <form onSubmit={handleCustomer}>
        <div className="lg:tw-flex md:tw-flex-none">
            <div className="lg:tw-w-3/5 md:tw-w-11/12 tw-mb-14">
                <H2>Delivery Address</H2> 
                    <div className="tw-relative tw-pt-4 tw-flex-auto">
                    <div className="tw--mx-2 tw-mb-3 md:tw-flex">
                        <div className="md:tw-w-1/2 tw-px-2 tw-mb-2">
                            <Span weight="semibold">First name</Span>
                            <Input outline="grey" type="text"
                            id='first_name' {...formik.getFieldProps('first_name')}/>
                            {formik.touched.first_name && formik.errors.first_name? <div className="error">{formik.errors.first_name}</div> : null}
                        </div>
                        <div className="md:tw-w-1/2 tw-px-2">
                            <Span weight="semibold">Last name</Span>
                            <Input outline="grey" type="text"
                            id='last_name' {...formik.getFieldProps('last_name')}/>
                            {formik.touched.last_name && formik.errors.last_name? <div className="error">{formik.errors.last_name}</div> : null}
                        </div>
                    </div>

                    <div className='tw-mb-3'> 
                        <Span weight="semibold">Unit</Span>
                        <Input outline="grey" type="text" placeholder="House number"
                        id='unit' {...formik.getFieldProps('unit')}/>
                        {formik.touched.unit && formik.errors.unit? <div className="error">{formik.errors.unit}</div> : null}
                    </div>        

                    <div className='tw-mb-3'> 
                        <Span weight="semibold">Address</Span>
                        <Input outline="grey" type="text" placeholder="Address"
                        value={location_address.address} disabled/>
                        {/* {formik.touched.address && formik.errors.address? <div className="error">{formik.errors.address}</div> : null} */}
                    </div>        

                    <div className='tw-mb-3'> 
                        <Span weight="semibold">Phone</Span>
                        <Input outline="grey" type="number"
                        id='phone' {...formik.getFieldProps('phone')}/>
                        {formik.touched.phone && formik.errors.phone? <div className="error">{formik.errors.phone}</div> : null}
                    </div>        

                    <div className='tw-mb-3'> 
                        <Span weight="semibold">Email Address</Span>
                        <Input outline="grey" type="text"
                        id='email' {...formik.getFieldProps('email')}/>
                        {formik.touched.email && formik.errors.email? <div className="error">{formik.errors.email}</div> : null}
                    </div>        

                    <Span weight="semibold">Additional Information</Span>
                    <Label padding="none"><i>Order Notes(Optional)</i></Label>
                    <Textarea placeholder="Notes about your order, e.g special notes for delivery." id='note' {...formik.getFieldProps('note')}/>  
                    </div> 
            </div>
            
            <BoxContainer width="small" margin="left" background="grey" border='none'>
                <H2>Product</H2>
                <div className="tw-border-b tw-border-t tw-border-themegrey-400 tw-py-2">
                {cartItems.map((item) => (<> 
                <div className='tw-flex tw-justify-between tw-mb-1'>
                    <div className="tw-flex tw-pr-2" key={item.id}>
                        <Span weight="semibold" type="grey">{item.title} {item.unit}
                        <Span weight="semibold" padding="primary">x {item.quantity}</Span>
                        </Span>
                    </div>
                    <div>
                        <Span weight="semibold">{`₱${parseFloat(item.subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                    </div> 
                </div> 
                </>))}
                </div>  

                <div className="tw-flex tw-py-1">
                    <div className="tw-w-1/2 tw-text-left tw-my-auto">
                        <Span weight="semibold">Subtotal</Span>
                    </div>
                    <div className="tw-w-1/2 tw-text-right">
                        <Span weight="bold" type="darkgreen">{`₱${parseFloat(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                    </div>
                </div> 

                <div className="tw-flex tw-py-1">
                    <div className="tw-w-1/2 tw-text-left tw-my-auto">
                        <Span weight="semibold">Pabili Fee</Span>
                    </div>
                    <div className="tw-w-1/2 tw-text-right">
                        <Span weight="bold" type="darkgreen">{`₱${parseFloat(data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                    </div>
                </div> 

                <div className="tw-flex tw-py-2 tw-border-t tw-border-themegrey-400">
                    <div className="tw-w-1/2 tw-text-left tw-my-auto">
                        <Span weight="semibold">Total</Span>
                    </div>
                    <div className="tw-w-1/2 tw-text-right">
                        <Span weight="bold" type="darkgreen">{`₱${parseFloat(total + data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                    </div>
                </div> 

                <Label type="black">
                    our personal data will be used to process your order,
                    support your experience throughout this website, adn
                    for other purposes described in our <a className="green">privacy policy</a>
                </Label>
 
                <Button type="green" border="green" padding="primary"  
                    disabled={!(formik.dirty && formik.isValid)}
                >
                    Checkout
                </Button> 
                    
            
            </BoxContainer>
            </div>
        </form> 
        </SubContainer> 
        
    </>);
}