import React from 'react' 
import { SubContainer, BannerPageContainer, BoxContainer} from '../components/common/Container' 
import { IconInput } from '../components/common/Input'  
import { Button } from '../components/common/Button'   
import { Link } from 'react-router-dom'
import { H1, H2 } from '../components/common/Heading'      
import { Span } from '../components/common/Span'           
import { Label } from '../components/common/Label'    
import { RiArrowRightSLine } from "react-icons/ri";
import { HiOutlineUser } from "react-icons/hi" 
import { BsCreditCard } from "react-icons/bs"
import { BiLock } from "react-icons/bi"
import { IoIosCalendar } from "react-icons/io"

import cod from '../images/codgrey.png'   

import * as Yup from 'yup'
import {useFormik } from 'formik'

const initialValues = {
    card_name: '',
    card_number:'',
    month:'',  
    year: '',
    cvc: ''
  }
  
  const onSubmit = values => {
    console.log('Form data', values)
  }
  
  const validationSchema = Yup.object({
    card_name: Yup.string().required('Required!'),
    card_number: Yup.string().required('Required!'),
    month: Yup.string().max(2, "Invalid Year!").required('Required!'),
    year: Yup.string().max(4, "Invalid Year!").required('Required!'),
    cvc: Yup.string().max(3, "Invalid Year!").required('Required!'), 
  })


export default () => {
    
    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

    return (<> 
        <BannerPageContainer background="cart">
            <H1 weight="bold" type="darkgreen">Checkout</H1>
            <div className="tw-flex">
                <a href="/" className="darkgreen">Home</a>
                <RiArrowRightSLine className="tw-text-base tw-mt-1 tw-text-themeblack-100"/>
                <Span>Checkout </Span>   
            </div>
        </BannerPageContainer>

        <SubContainer width="tertiary">  
        <div className="lg:tw-flex md:tw-flex-none">
            <div className="lg:tw-w-3/5 md:tw-w-11/12 tw-mb-14">
                <H2 margin="bottom">Payment Details</H2> 
                
                <div className='tw-mb-6'>
                    <Span weight="semibold" transform="uppercase">Cardholder Name</Span>
                    <IconInput outline="grey" id='card_name' {...formik.getFieldProps('card_name')}>
                    <HiOutlineUser className="tw-text-2xl tw-absolute tw-m-1.5 tw-text-themegreen-400"/>
                    </IconInput>
                    {formik.touched.card_name && formik.errors.card_name? <div className="error">{formik.errors.card_name}</div> : null}
                </div>
                     
                <div className='tw-mb-6'>    
                    <Span weight="semibold" transform="uppercase">Card Number</Span>
                    <IconInput outline="grey" id='card_number' {...formik.getFieldProps('card_number')}>
                        <BsCreditCard className="tw-text-2xl tw-absolute tw-m-1.5 tw-text-themegreen-400"/>
                    </IconInput>
                    {formik.touched.card_number && formik.errors.card_number? <div className="error">{formik.errors.card_number}</div> : null}
                </div>

                <div className="tw-flex">
                    <div className="lg:tw-w-1/3 md:tw-w-11/12 tw-mr-5"> 
                        <Span weight="semibold" transform="uppercase">Expiry Month</Span>
                        <IconInput outline="grey" id='month' {...formik.getFieldProps('month')}>
                            <IoIosCalendar className="tw-text-2xl tw-absolute tw-m-1.5 tw-text-themegreen-400"/>
                        </IconInput>
                        {formik.touched.month && formik.errors.month? <div className="error">{formik.errors.month}</div> : null}
                   </div>

                    <div className="lg:tw-w-1/3 md:tw-w-11/12 tw-mx-2">
                        <Span weight="semibold" transform="uppercase">Expiry Year</Span>
                        <IconInput outline="grey" id='year' {...formik.getFieldProps('year')}>
                            <IoIosCalendar className="tw-text-2xl tw-absolute tw-m-1.5 tw-text-themegreen-400"/>
                        </IconInput>
                        {formik.touched.year && formik.errors.year? <div className="error">{formik.errors.year}</div> : null}
                   </div>

                    <div className="lg:tw-w-1/3 md:tw-w-11/12 tw-ml-5">
                        <Span weight="semibold" transform="uppercase">CVC</Span>
                        <IconInput outline="grey" id='cvc' {...formik.getFieldProps('cvc')}>
                            <BiLock className="tw-text-2xl tw-absolute tw-m-1.5 tw-text-themegreen-400"/>
                        </IconInput>
                        {formik.touched.cvc && formik.errors.cvc? <div className="error">{formik.errors.cvc}</div> : null}
                   </div>
                </div>

                <div className="tw-text-right">
                    <Link to="/placeorder">
                    <Button type="green" radius="rounded" border="green" width="none" transform="uppercase" padding="primary">
                        Pay
                    </Button>
                    </Link>
                </div>
            </div>
            
            <BoxContainer width="small" margin="left">
                <H2>Order Summary</H2> 
                <div className="tw-border-t tw-border-b tw-border-themegrey-100 tw-h-72">
                    <div className="tw-flex tw-py-2">
                        <div className="tw-w-1/2 tw-text-left">
                            <Span weight="semibold">Subtotal</Span>
                        </div>
                        <div className="tw-w-1/2 tw-text-right tw-my-auto">
                            <Span weight="semibold">₱495</Span>
                        </div>
                    </div> 
                    <div className="tw-flex tw-py-2">
                        <div className="tw-w-1/2 tw-text-left">
                            <Span weight="semibold">Delivery Fee</Span>
                        </div>
                        <div className="tw-w-1/2 tw-text-right tw-my-auto">
                            <Span weight="semibold">₱100</Span>
                        </div>
                    </div> 
                </div> 

                <div className="tw-flex tw-py-2">
                    <div className="tw-w-1/2 tw-text-left tw-my-auto tw-flex">
                        <Span weight="bold">Total</Span>
                        <Label margin="top" padding="primary">(VAT Included)</Label>
                    </div>
                    <div className="tw-w-1/2 tw-text-right">
                        <Span weight="bold" type="darkgreen">₱495</Span>
                    </div>
                </div> 
            </BoxContainer>
        </div>
        </SubContainer>  
    </>);
}