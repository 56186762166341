import React, { useState, useEffect } from 'react' 
import { BannerPageContainer, SubContainer, Container} from '../components/common/Container'  
import { H1 } from '../components/common/Heading' 
import { TrackOrderInput } from '../components/common/Input'     
import { Span } from '../components/common/Span'      
import { Label } from '../components/common/Label' 
import { IoSearch } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa"; 
import BestSeller from '../components/BestSeller'

import { RiArrowRightSLine } from "react-icons/ri";
import api from '../network/api' 

export default (props) => {

    const [order, setOrder] = useState([]);  
    const [error, setError]= useState("");

    const fetchData = async() => {
        api.get("/get-order-list")
            .then(res => {
                return res.data.data.order;
            })
            .then(data => {setOrder(data);
            })
            .catch(error => {
                setError(error.message);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Search
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
  
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = order.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(order)
        }
    }

    const filteredData = order.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })

    return (<> 
        <BannerPageContainer background="order">
            <H1 weight="bold" type="darkgreen">Track Your Order</H1>
            <div className="tw-flex">
                <a href="/" className="darkgreen">Home</a>
                <RiArrowRightSLine className="tw-text-base tw-mt-1 tw-text-themeblack-100"/>
                <Span>Track Order </Span>   
            </div>
        </BannerPageContainer>

        <SubContainer width="primary">  
            <TrackOrderInput 
                type="text" placeholder="Tracking number.." 
                value={searchInput}
                onChange={(e:any) => searchItems(e.target.value)}
            > 
                <IoSearch className="search-icon"/>
            </TrackOrderInput>


 
            <div className="tw-p-5">  
            
                {order.map((orders:any, index:any) =>   
                        <div className="tw-flex" key={index}>
                        <div className="tw-p-3 tw-m-auto">
                            <FaCheckCircle className="check"/>
                        </div>
                        <Container>
                            <Span weight="semibold">{orders.status}</Span>
                            <Label>{orders.date_processed}</Label>
                        </Container> 
                    </div> 
                )}

                {error}
            </div> 
 
        </SubContainer>  
        {/* <SubContainer width="secondary">  
            <BestSeller/>   
        </SubContainer>   */}
    </>);
}