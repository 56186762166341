import { useState, useEffect } from "react"; 
import { QuantityContainer } from './common/Container'
import { Link } from 'react-router-dom' 
import { QuantityInput } from './common/Input' 
import { QuantityButton, Button, CartButton } from './common/Button'
import { Span } from './common/Span'   
import { BsTrash } from "react-icons/bs"; 
import shopping from '../images/noto_shopping-cart.png'
import { useHistory } from "react-router-dom"; 
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store'

import { getHomeDetails } from '../network/product'

let default_img = 'https://static.jnb.ph/mommymili/product.jpg'

const AddItem = ({cartItems, handleEmptyProduct, updateItem, total, removeItem, setCartModal}) => {
  const [count, setCount] = useState(0);   

  function handlePlus(key:any) {
    console.log("testkey",cartItems[key].quantity + 1)
    let item = cartItems[key]
    item.quantity = item.quantity + 1;
    updateItem(item) 
  }

  function handleMinus(key:any) {
    console.log("testkey",cartItems[key].quantity - 1)
    let item = cartItems[key]
    item.quantity = item.quantity - 1;
    if(item.quantity <= 0){
      item.quantity = 0;
      removeItem(item) 
    }else{
      updateItem(item)
    }
  }

  function handleRemoveItem(key:any, e:any) { 
    e.preventDefault(); 
    let item = cartItems[key]
    removeItem(item) 
  }  

  function handleQuantity(e) {
    e.preventDefault();  
    setCount(count)
  } 

  const market: any = useSelector(
    (state: RootState) => state.cart.market
  );

  const history = useHistory();
  const handleShop = (e) => { 
    history.push('/market/'+(market.name ? market.name.toLowerCase() : '')); 
    setCartModal(false);
  }; 
 
  const [data, setData] = useState({} as any);  
  const dispatch = useDispatch<Dispatch>();
  const getAllDetails = async() => { 
    let {details } : any = await getHomeDetails(market.name ? market.name.toLowerCase() : '');

    dispatch.cart.setMarket(details)
    setData(details);   
  }

  useEffect(() => {
    getAllDetails();
  }, []); 

return (<> 
      {/* Empty Cart */}
      {cartItems.length ===  0 && (
      <div className="tw-my-20 tw-h-96"> 
        <div className="tw-m-auto">
          <img src={shopping} className="tw-h-40 tw-mx-auto tw-my-2"/>
          <Span type='grey' size='lg'>No products in the cart.</Span> 
          <div className='tw-mt-4'> 
              <Button  
                type='darkgreen'
                border='green' 
                weight='medium' 
                radius='rounded' 
                width='none' 
                padding='primary'
                onClick={handleShop}>
                  Let's Shop
              </Button>  
          </div> 
        </div>
        </div> 
      )}

    {cartItems.length !=  0 && (<>
    <div className="tw-p-2 tw-h-80 tw-overscroll-contain tw-overflow-auto"> 
      
      {/* with Product */}
      {cartItems.map((item, key) => (
        <div key={item.id} className="tw-flex tw-my-2 tw-justify-between">
          <div className='tw-flex'>
            <div className='tw-my-auto'>
              <img src={item.image ? item.image : default_img} alt="" className="cart-image"/>
            </div>
            
            <div className="tw-w-60 tw-text-left tw-mx-2 tw-my-auto">
              <Span weight="medium">{item.title} {item.unit}</Span>  
              <div className="tw-flex"> 
                <Span type="darkgreen" padding="primary">
                  {`₱${parseFloat(item.price).toFixed(2)}`}
                </Span>
              </div> 
            </div>
          </div>

          <div className="tw-flex tw-my-auto"> 
            <QuantityContainer background="green">
              <QuantityButton onClick={() => handleMinus(key)} type="none" weight="bold"> - </QuantityButton>  
              <QuantityInput
                outline="green" width="eight" color="white"
                type="text" 
                value={item.quantity}
                onChange={handleQuantity}
              /> 
              <QuantityButton onClick={() => handlePlus(key)} type="none" weight="semibold">+</QuantityButton> 
            </QuantityContainer>
              <a href="" onClick={(e) => handleRemoveItem(key, e)} className="tw-my-auto tw-mx-2">
                <BsTrash className="tw-text-lg tw-text-themegreen-400"/>
              </a>
          </div> 
          
          <div className='tw-hidden'>
            {item.quantity} * {item.price}
          </div>
        </div> 
      ))}  
    </div> 
 
    <div className="">
        <div className="tw-flex tw-m-auto tw-border-t-2 tw-p-2"> 
          <CartButton textcolor="green" padding="none" justify='start' onClick={handleEmptyProduct}> 
            <BsTrash className="card-bag"/>
            Empty Cart
          </CartButton> 
        </div>

        <div className="tw-bg-gray-100 tw-p-2">
            <div className="tw-flex tw-py-1">
                <div className="tw-w-1/2 tw-text-left"><Span weight="semibold">Subtotal</Span></div>
                <div className="tw-w-1/2 tw-text-right"><Span weight="semibold" type="darkgreen">
                {`₱${parseFloat(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span></div>
            </div>    

            <div className="tw-flex tw-py-1">
                <div className="tw-w-1/2 tw-text-left"><Span weight="semibold">Pabili Fee</Span></div>
                <div className="tw-w-1/2 tw-text-right">
                  <Span weight="semibold" type="darkgreen">
                    {`₱${parseFloat(data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} 
                </Span></div> 
            </div>    
 
            <div className="tw-flex tw-border-t tw-border-gray-300 ">
                <div className="tw-w-1/2 tw-text-left tw-py-1"><Span weight="semibold">Total</Span></div>
                <div className="tw-w-1/2 tw-text-right">
                  <Span weight="semibold" type="darkgreen">
                  {`₱${parseFloat(total + data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} 
                </Span></div> 
            </div>    

            <Link to="/cod"><Button type="green" border="green" padding="primary">Checkout</Button></Link>
            <Link to="/viewcart"><Button textcolor="black" padding="primary">View Cart</Button></Link>
        </div>
    </div>
    </>)}

  </>)
};

export default AddItem;