import axios from 'axios'
import { API_URL } from './contants'

// Add a response interceptor
axios.interceptors.response.use((response) => {
  return response;
}, (error)=>{
  return error;
});

axios.defaults.baseURL = API_URL
export default axios;