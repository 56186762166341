import React from 'react'
import CashOnDeliveryPage from '../containers/CashOnDeliveryPage'  
import Searchbar from '../containers/Searchbar'   
 
const cashOnDelivery = () => {
    return ( 
        <>
            <Searchbar />
            <CashOnDeliveryPage/>  
        </>
    )
}

export default cashOnDelivery
