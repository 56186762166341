import React from 'react' 
import { useStars } from 'stars-rating-react-hooks'

function StarsRating() {
    const config = {
        totalStars: 5,
        initialSelectedValue: 5, 
        renderFull: '★',
        renderEmpty: '☆',
      };

      const {
        stars,
        getStarProps,
        getStarWrapperProps,
        isSelecting,
        selectingValue,
        selectedValue,
      } = useStars(config);
    return (
        <span
              {...getStarWrapperProps({
                style: {
                  cursor: 'pointer',
                  display: 'inline-block'
                },
              })}
            >
              {stars?.map((star, i) => (
                <span
                  key={i}
                  {...getStarProps(i, {
                    style: {
                      fontSize: '20px',
                      color: '#f5c136',
                      display: 'inline-block'
                    },
                    onClick: (event, ratedValue) => {
                      console.log(`You just rated ${ratedValue} Stars!!`);
                    },
                  })}
                >
                  {star}
                </span>
              ))}
            </span>
    )
}

export default StarsRating
