import api from './api'
import { store } from "../store";

let order = async(order:any) => { 
    let result = await api.post('/order', order); 
  
    if (result.status == 200) {
      return {...result.data}
    } else {
      return false;
    }
  } 
 

export {
  order, 
}
  