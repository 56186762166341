import React from 'react'
import PlaceOrderPage from '../containers/PlaceOrderPage'  
import Searchbar from '../containers/Searchbar'  
import { useParams } from "react-router-dom"; 
 
const PlaceOrder = () => {
        let params:any = useParams();

        console.log("params", params)

    return (  
        <>
            <Searchbar />
            <PlaceOrderPage order_id={params.id}/>  
        </>
    )
}

export default PlaceOrder
