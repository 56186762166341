import { ModalStatusContainer } from './common/Container' 
import { Span } from './common/Span'  
import arrow from '../images/arrow.png'

// type Props = {
//     setStatusModal: Function 
//   }

export default () => { 
  return (<>  
    <ModalStatusContainer width='none' position='start-end' margin='top-24' background='none'>   
        <div className='tw-justify-center' >
            <img src={arrow} className='arrow-status'/> 
        </div>    
        <div className='tw-bg-white'>
            <div className='tw-flex tw-m-2.5 '>
                <span className="tw-rounded-2xl tw-bg-themegreen-400 tw-px-2 tw-py-0.5 tw-mx-1 tw-text-white tw-font-bold">1</span><Span size='base' weight='medium'>Item(s) Added</Span>  
            </div>   
        </div>
    </ModalStatusContainer>  
  </>);
} 