import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLProps<HTMLTextAreaElement> & {
  children?: React.ReactNode,
  outline?: 'default' | 'grey'
}

let getOutline = (outline: Props['outline']) => {
    switch (outline) {  
      default:
        return 'focus:tw-outline-none tw-border-themegrey-100 focus:tw-border-themegreen-400'; 
    }
  }
 

export const Textarea = ({outline, children, ...props} : Props) => (  
  <textarea className={classnames("tw-appearance-none tw-block tw-w-full tw-text-gray-700 tw-border tw-rounded tw-p-2 tw-mb-3 tw-leading-tight ",
    getOutline(outline))}
    {...props}/>
);