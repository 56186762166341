import * as React from 'react';
import classnames from 'classnames' 
import classNames from 'classnames'; 
import { positions } from '@mui/system';

type Props = {
  children: React.ReactNode, 
  position?: 'default' | 'start' | 'end' | 'fixed' | 'center' | 'between' | 'none' | 'start-end',
  width?: 'default' | 'primary' | 'secondary' | 'tertiary' | 'ten' | 'full' | 'half' | 'small' | 'none',
  overflow?: 'default' | 'none' | 'scroll',
  background?: 'default' | 'order' | 'cart' | 'grey' | 'lightgrey' | 'green' | 'none',
  margin?: 'default' | 'left' | 'left10' | 'none' | 'my' | 'top' | 'top-20' | 'top-24' | 'mt-2' | 'top-44'| 'my-4',
  border?: 'default' | 'darkgrey' | 'grey' |'none'
  padding?: 'none',
  flex?: 'none',  
}

let getPosition = (position: Props['position']) => {
  switch (position) {
    case 'none':
      return ''
    case 'fixed':
      return 'tw-fixed tw-auto tw-w-full md:tw-w-10/12 lg:tw-w-7/12';
    case 'between':
      return 'tw-justify-between';
    case 'center':
      return 'tw-items-center tw-justify-center ';
    case 'start-end':
      return 'tw-items-start tw-justify-end tw-right-20';
    case 'end':
      return 'tw-items-end tw-justify-end ';
    case 'start':
      return 'tw-items-start tw-justify-center ';
    default:
      return "tw-items-start md:tw-items-center";
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) {
    case 'none':
      return ''
    case 'small':
      return 'md:tw-w-11/12 lg:tw-w-2/5'
    case 'half':
      return 'tw-w-full lg:tw-w-4/12';
    case 'full': 
      return "tw-w-full";  
    case 'ten':
      return 'md:tw-w-11/12 lg:tw-w-10/12'
    case 'tertiary':
      return 'md:tw-w-11/12 lg:tw-w-9/12'
    case 'secondary':
      return "md:tw-w-11/12 lg:tw-w-7/12";
    case 'primary':
      return "tw-w-full lg:tw-w-5/12 tw-px-5";
    default:
      return "tw-max-w-xl";
  }
}

let getOverflow = (overflow: Props['overflow']) => {
  switch (overflow) {
    case 'scroll':
      return 'tw-overflow-y-scroll tw-h-3/5';
    case 'none':
      return 'tw-overflow-none';
    default:
      return "tw-overflow-x-hidden tw-overflow-y-auto";
  }
}

let getBackground = (background: Props['background']) => {
  switch (background) { 
    case 'green':
      return 'tw-bg-themegreen-300'
    case 'lightgrey': 
      return 'tw-bg-themegrey-500'
    case 'grey': 
      return 'tw-bg-themegrey-200'
    case 'order':
      return ' tw-bg-order tw-bg-cover';
    case 'cart':
      return 'tw-bg-cart tw-bg-cover';
    case 'none':
      return ''
    default:
      return "tw-bg-white";
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {   
    case 'top-44':
      return 'tw-mx-auto tw-mt-44';
    case 'top-20':
      return 'tw-mx-auto tw-mt-20';
    case 'top-24':
      return 'tw-mx-auto tw-mt-24';
    case 'top':
      return ' tw-mx-auto tw-mt-32';
    case 'none':
      return 'tw-m-0';
    case 'mt-2':
      return 'tw-mt-2';
    case 'my':
      return 'tw-my-10'; 
    case 'my-4':
      return 'tw-my-4';
    case 'left10':
      return 'lg:tw-ml-10 md:tw-ml-0';
    case 'left':
      return 'lg:tw-ml-20 md:tw-ml-0';
    default:
      return "tw-m-auto";
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {   
    case 'none':
      return 'tw-border-none';
    case 'darkgrey':
      return "tw-border-4 tw-border-themegrey-400 ";
    case 'grey':
      return "tw-border-2 tw-border-themegrey-100"
    default:
      return "tw-border-2 tw-rounded-md tw-border-themegrey-200 ";
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) {   
    case 'none':
      return ''; 
    default:
      return "tw-p-8";
  }
}

let getFlex = (flex: Props['flex']) => {
  switch (flex) {   
    case 'none':
      return ''; 
    default:
      return "tw-flex";
  }
}

export const ModalContainer = ({position, width, overflow, flex, margin, background, children }: Props) => {
  return <div className={classnames("tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none", 
          getPosition(position), getOverflow(overflow), getFlex(flex), getMargin(margin), )}>
            <div className={classnames(getWidth(width))}>
                <div className={classnames("tw-shadow-lg tw-relative tw-flex-col tw-w-full tw-outline-none focus:tw-outline-none",
                getFlex(flex), getBackground(background))}>
                {children}
                </div>
            </div>
          </div>
}

export const ModalStatusContainer = ({position, width, overflow, flex, margin, background, children }: Props) => {
  return <div id='hideStatus' className={classnames("md:tw-invisible lg:tw-visible tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none", 
          getPosition(position), getOverflow(overflow), getFlex(flex), getMargin(margin), )}>
            <div className={classnames(getWidth(width))}>
                <div className={classnames("tw-shadow-lg tw-relative tw-flex-col tw-w-full tw-outline-none focus:tw-outline-none",
                getFlex(flex), getBackground(background))}>
                {children}
                </div>
            </div>
          </div>
}

export const TabContainer = ({ flex, children }: Props) => {
  return <div className={classNames("tw-flex-auto", getFlex(flex))}> 
            <div className={classNames("tw-container tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-3 tw-mt-5")}>  
              {children}
            </div>
          </div>
}

export const FeaturedContainer = ({ flex, children }: Props) => {
  return <div className={classNames("tw-align-middle tw-items-center tw-text-center tw-justify-center", 
          getFlex(flex))}> 
            <div className={classNames("tw-container tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-3")}>  
              {children}
            </div>
          </div>
}

export const SubContainer = ({ width, padding, flex, margin, children }: Props) => {
  return <div className={classNames("tw-justify-center md:tw-items-center",
          getWidth(width), getPadding(padding), getMargin(margin), getFlex(flex))}> 
            <div className={classNames("tw-flex-1")}>  
              {children}
            </div>
          </div>
}


export const BannerContainer = ({ children }: Props) => {
  return <div className="tw-relative tw-h-36">
              {children} 
          </div>
}  

export const Container = ({ children }: Props) => {
  return <div className="tw-border-b tw-border-themegrey-100 tw-w-full tw-pt-3">
              {children} 
          </div>
}
 
export const BannerPageContainer = ({ background, flex, children }: Props) => {
  return <div className={classNames("tw-h-36 lg:tw-mt-40 tw-mb-10",
            getBackground(background), getFlex(flex))}>
            <div className="tw-my-auto tw-ml-10 lg:tw-ml-48">
              {children} 
            </div>
          </div>
} 

export const BoxContainer = ({ width, border, margin, background, children }: Props) => {
  return <div className={classNames( getWidth(width), getMargin(margin) )}>
            <div className={classNames("tw-p-4",
              getBackground(background), getBorder(border))}>
              {children} 
            </div>
          </div>
}

export const QuantityContainer = ({ background, flex, margin, children }: Props) => {
  return  <div className={classNames("tw-rounded-2xl tw-px-2 tw-text-white tw-font-bold",
              getBackground(background), getFlex(flex), getMargin(margin))}>
              {children}  
          </div>
}
 
export const HeaderContainer = ({ background, flex, width, position, children }: Props) => {
  return  <div className={classNames("",
              getBackground(background), getFlex(flex), getWidth(width), getPosition(position))}>
              {children}  
          </div>
}

export const GridContainer = ({children }: Props) => { 
  return <div className={classnames("tw-grid lg:tw-grid-cols-2 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-5",
          )}>
            {children} 
        </div>
}

