import React from 'react' 
import { SubContainer, BannerPageContainer, BoxContainer} from '../components/common/Container'   
import { Button } from '../components/common/Button' 
import { Link } from 'react-router-dom'
import { H1, H2 } from '../components/common/Heading'      
import { Span } from '../components/common/Span'           
import { Label } from '../components/common/Label'    
import { RiArrowRightSLine } from "react-icons/ri";

import cod from '../images/codgrey.png'   

export default () => {
    return (<> 
        <BannerPageContainer background="cart">
            <H1 weight="bold" type="darkgreen">Checkout</H1>
            <div className="tw-flex">
                <a href="/" className="darkgreen">Home</a>
                <RiArrowRightSLine className="tw-text-base tw-mt-1 tw-text-themeblack-100"/>
                <Span>Checkout </Span>   
            </div>
        </BannerPageContainer>

        <SubContainer width="tertiary">  
        <div className="lg:tw-flex md:tw-flex-none">
            <div className="lg:tw-w-3/5 md:tw-w-11/12 tw-mb-14">
                <H2>Payment Method</H2> 
                <Link to="/cod"><div className="cod"/></Link> 
                <Link to="/card"><div className="credit"/></Link>
            </div>
            
            <BoxContainer width="small" margin="left">
                <H2>Order Summary</H2> 
                <div className="tw-border-t tw-border-b tw-border-themegrey-100 tw-h-72">
                    <div className="tw-flex tw-py-2">
                        <div className="tw-w-1/2 tw-text-left">
                            <Span weight="semibold">Subtotal</Span>
                        </div>
                        <div className="tw-w-1/2 tw-text-right tw-my-auto">
                            <Span weight="semibold">₱495</Span>
                        </div>
                    </div> 
                    <div className="tw-flex tw-py-2">
                        <div className="tw-w-1/2 tw-text-left">
                            <Span weight="semibold">Delivery Fee</Span>
                        </div>
                        <div className="tw-w-1/2 tw-text-right tw-my-auto">
                            <Span weight="semibold">₱100</Span>
                        </div>
                    </div> 
                </div> 

                <div className="tw-flex tw-py-2">
                    <div className="tw-w-1/2 tw-text-left tw-my-auto tw-flex">
                        <Span weight="bold">Total</Span>
                        <Label margin="top" padding="primary">(VAT Included)</Label>
                    </div>
                    <div className="tw-w-1/2 tw-text-right">
                        <Span weight="bold" type="darkgreen">₱495</Span>
                    </div>
                </div> 
            </BoxContainer>
        </div>
        </SubContainer>  
    </>);
}