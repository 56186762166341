import { useState, useEffect } from 'react' 
import { FeaturedContainer } from '../../components/common/Container'
import { Link } from 'react-router-dom'  
import { getHomeDetails } from '../../network/product' 
import { useDispatch } from 'react-redux'
import { Dispatch } from '../../store'
import Loading from '../../components/Loading'
 
let default_img = 'https://static.jnb.ph/mommymili/product.jpg'


export default ({page, market,...props} : any) => { 

    let banner = "https://cdn.shopify.com/s/files/1/0505/5145/5923/files/Mommymili_Palengke_Delivery_with_Get_All_1024x1024.jpg?v=1621044036";
    
    const [data, setData] = useState({} as any);  
    const [categories, setcategories] = useState({
      data: []
    });  
    const [featured_merchants, setFeaturedMerchants] = useState([]); 
    const dispatch = useDispatch<Dispatch>();

    const [loading, setLoading] = useState(true);
    const getAllDetails = async() => {
      
      let {details, categories, featured_merchants } : any = await getHomeDetails(market);

      dispatch.cart.setMarket(details)
      setData(details);
      setcategories(categories);
      setFeaturedMerchants(featured_merchants);
      setLoading(false) 
    }

    useEffect(() => {
      getAllDetails();
    }, []);

    return (<>
    {loading ?  <> 
      <Loading /> 
    </>:<>  
      <div className="page-width">
      <div className="tw-grid">
        <div className="grid__item medium-up--five-sixths medium-up--push-one-twelfth">
         <div className="rte">
            <div className='tw-text-center tw-mt-52'> 
            <img src={data.banner} alt="MommyMili Palengke Delivery with Get All" />  
            </div>
             
            <h3><strong>FEATURED PRODUCT</strong></h3> 
            <div className="tw-flex tw-flex-auto"> 
              <div className="container tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-3">
              {
                categories.data.map((category:any, index) => {
                
                return <div className="tw-w-full"> 
                  <Link to={`/category/${category.id}`} >
                    <img src={category.thumbnail_image } className='product' onError={(e:any)=>{e.target.onerror = null; e.target.src=default_img}} />
                  </Link> 
                </div>
                })
              }
              </div>
            </div>  

            {console.log("featured_merchants", featured_merchants)} 
            { featured_merchants.length > 0 ? <> 
            <h3><strong>FEATURED MERCHANT</strong></h3> 
            <FeaturedContainer>
              {
                featured_merchants.map((merchant:any, index) => { 
                return <div className="tw-w-full"> 
                  <Link to={`/merchant/${merchant.id}`} > 
                    <img src={merchant.thumbnail_image} className='product' onError={(e:any)=>{e.target.onerror = null; e.target.src=default_img}} />
                  </Link> 
                </div>
                })
              }
            </FeaturedContainer> 
            </> : <></>}
  
            <div className="tw-my-10">
            <p style={{textAlign: 'center'}}><em>To know more, you may visit our<span>&nbsp;</span><a href="https://getall.com.ph/pages/getallfaqs" target="_blank" title="Get All Delivery Service FAQs" rel="noopener noreferrer" aria-describedby="a11y-new-window-message">FAQs page</a>. You may also visit us
                on<span>&nbsp;</span><a href="https://www.facebook.com/getallph" target="_blank" title="Get All Facebook Page" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Facebook</a>,<span>&nbsp;</span><a href="https://www.instagram.com/getall_ph/" target="_blank" title="Get All Instagram Page" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Instagram</a>,<span>&nbsp;</span><a href="https://www.youtube.com/c/GetAllDeliveryServices" target="_blank" title="Get All Youtube Channel" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Youtube</a>, and<span>&nbsp;</span><a href="https://www.tiktok.com/@getallph?lang=en" target="_blank" title="Get All on Tiktok" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">TikTok</a>. </em></p>
            <p style={{textAlign: 'center'}}><em>Don’t forget to<span>&nbsp;</span><a href="https://getall.com.ph/pages/download-the-getall-app" target="_blank" title="Download the Get All App" rel="noopener noreferrer" aria-describedby="a11y-new-window-message">Download the Get All
                  app</a><span>&nbsp;</span>here:&nbsp;<a href="http://bit.ly/GetAllonAndroid" target="_blank" title="Get All Delivery Service on Android" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Google Play Store</a>&nbsp;|&nbsp;<a href="http://bit.ly/GetAllonApple" target="_blank" title="Get All Delivery App on Apple" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Apple App
                  Store</a>&nbsp;|&nbsp;<a href="http://bit.ly/GetAllonHuawei" target="_blank" title="Get All Delivery App on Huawei AppGallery" rel="noopener noreferrer" aria-describedby="a11y-new-window-external-message">Huawei AppGallery</a></em></p>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </>} 
  </>);
}
