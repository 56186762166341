import Swal from 'sweetalert2';
import api from './api'


const getHomeDetails = async(market) => {

    try {   
      if(!market){
        market = "other" 
      }

      let response = await api.get(`home/${market}`)
      console.log('error', response.data.data)

      let {details, categories, featured_merchants }  = response.data.data

      return {
          details,
          categories,
          featured_merchants
      }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }

  

  const getProductsByCategory = async(category, market) => {
  
      try {
              
        if(!market){
          market = "other" 
        }
        
        let response = await api.get(`${market}/products/`+category) 
        //   console.log('error', response.data.data)

            return response.data.data; 
  
      } catch (error) {
          console.log('error', error)
          Swal.fire('error');
          return false
      }
  } 

    
  const getProductsByMerchant = async(merchant) => {
  
    try {
      // if(!market){
      //   market = "other" 
      // }
            
        let response = await api.get(`/products-merchant/`+merchant)
        console.log('error', response.data.data)

        let {details, products }  = response.data.data

        return {
            details,
            products
        }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }

  const getOrderDetails = async(order_id) => {  

    try {   
        let response = await api.get(`/get-order-details/`+order_id)

        console.log('error', response.data.data)

        let {order, order_items }  = response.data.data

        return {
          order,
          order_items
      }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }  

  const getItemDetails = async(order_id) => {  

    try {   
        let response = await api.get(`/order-item/`+order_id) 
        console.log('error', response.data.data)

        let {order_items }  = response.data.data

        return { 
          order_items
      }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }  

  const getCategory = async() => {  

    try {   
        let response = await api.get(`/get-category-list`) 
        console.log('error', response.data.data.data)

        let { data }  = response.data.data

        return {
          data
      }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }   

  const getMerchant = async() => {  

    try {   
        let response = await api.get(`/get-merchant-list`) 
        console.log('error', response.data.data.data)

        let { data }  = response.data.data

        return {
          data
      }

    } catch (error) {
        console.log('error', error)
        Swal.fire('error');
        return false
    } 
  }     

  export {
    getHomeDetails,
    getProductsByCategory,
    getProductsByMerchant,
    getOrderDetails,
    getItemDetails,
    getCategory,
    getMerchant
  }