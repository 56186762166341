import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import { models, RootModel } from "./models";
import storage from 'redux-persist/lib/storage'
import persistPlugin from '@rematch/persist';


const persistConfig = {
    key: 'root',
    storage,
    whitelist:['cart']
}


export const store = init({
  models,
  // @ts-ignore
  plugins: [persistPlugin(persistConfig)],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;