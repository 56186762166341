import { useEffect, useState } from 'react' 
import { Span } from './common/Span'
import scriptLoader from 'react-async-script-loader'; 
import PlacesAutocomplete from 'react-places-autocomplete';
import { IoSearchOutline } from "react-icons/io5";  
import { useSelector, useDispatch } from 'react-redux'
import { RootState, Dispatch } from '../store'
import { geocodeByPlaceId } from 'react-places-autocomplete';

import axios from 'axios';

function MapContainer({ isScriptLoaded, isScriptLoadSucceed, location}){
 
    const [address, setAddress] = useState({
        address: '',
    })

    const location_address: any = useSelector(
        (state: RootState) => state.cart.location.address
      );  

    const dispatch = useDispatch<Dispatch>();

    useEffect(() => {
        setAddress({address: location_address})
    }, [location_address])

    

    const handleChange = (address) => {   
        setAddress({ address });
    }
    
    const handleSelect = async (address, placeId) => {

        if(!placeId){
            return ;
        }
        let place = await geocodeByPlaceId(placeId);

        console.log("address", place[0])

        let location = {
            "lat" : place[0]?.geometry?.location?.lat(),
            "lng" : place[0]?.geometry?.location?.lng(),
            address
        }
        
        console.log("address", address, location)
        
        dispatch.cart.updateAddress(location)
        setAddress({address})
    }  

    if (isScriptLoaded && isScriptLoadSucceed) {
        return (
          <div>
            <PlacesAutocomplete value={address.address} onChange={handleChange} onSelect={handleSelect}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="tw-relative tw-my-3">  
                  <input
                        {...getInputProps({
                        placeholder: "Select Address...",
                        className: "loc-search",
                    })} 
                    />
                  <div>
                    {loading ? <div>Loading...</div>: null} 
                    {suggestions.map(suggestion => {   
                        const style =  {
                            backgroundColor: suggestion.active ? "#f2f2f2" : "#ffffff", 
                            cursor: "pointer",
                        };

                        return (
                            <div {...getSuggestionItemProps(suggestion, {style})}>
                                <div className='tw-text-left tw-p-2 tw-border-b tw-border-themegrey-400'>
                                    <Span padding='py'>{suggestion.description}</Span>
                                </div>
                            </div> 
                        );
                        })}
                    </div>
                    <IoSearchOutline className="search-icon" />
                </div>  
            )} 
            </PlacesAutocomplete>
        </div>  
    ); 
    }else {
        return <div></div>;
    }  
}

export default scriptLoader([
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDuGG_q0K0oMYLOtbgXhLJ6HupvZNjc338&libraries=places`,
])(MapContainer);