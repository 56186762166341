import React from 'react'
import TrackOrderPage from '../containers/TrackOrderPage'  
import Searchbar from '../containers/Searchbar'   
 
const TrackOrder = () => {
    return ( 
        <>
            <Searchbar />
            <TrackOrderPage/>  
        </>
    )
}

export default TrackOrder
