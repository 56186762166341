import React, { useState } from 'react' 
import { useParams } from "react-router-dom"; 

import CategoryProductList from '../containers/CategoryProductList';
import Searchbar from '../containers/Searchbar' 


const Category = () => {

    let params:any = useParams();

    console.log("params", params)

    return ( 
        <>
            <Searchbar />
            <CategoryProductList category={params.id}/>   
        </>
    )
}

export default Category
