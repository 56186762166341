import { SpinnerCircularFixed } from 'spinners-react';     

export default () => { 
    return <>
        <div className="tw-justify-center md:tw-items-center md:tw-w-11/12 lg:tw-w-7/12 tw-p-8 tw-mx-auto tw-mt-20 tw-flex"> 
        <div> 
            <div className='tw-my-auto tw-items-center tw-overscroll-none'> 
                <SpinnerCircularFixed className='tw-min-h-screen tw-m-auto'/>
            </div> 
        </div>
        </div>
    </>
}