import React from 'react'
import CardPage from '../containers/CardPage'  
import Searchbar from '../containers/Searchbar'   
 
const Card = () => {
    return ( 
        <>
            <Searchbar />
            <CardPage/>  
        </>
    )
}

export default Card
