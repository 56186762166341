import React from 'react'
import PaymentPage from '../containers/PaymentPage'  
import Searchbar from '../containers/Searchbar'   
 
const Payment = () => {
    return ( 
        <>
            <Searchbar />
            <PaymentPage/>  
        </>
    )
}

export default Payment
