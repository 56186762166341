import React from 'react' 
import { useStars } from 'stars-rating-react-hooks'
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { width } from '@mui/system';

function HeartRating() {
    const config = {
        totalStars: 1,
        initialSelectedValue: 0, 
        renderFull: <FaHeart/>,
        renderEmpty: <FaRegHeart/>,
      };

      const {
        stars,
        getStarProps,
        getStarWrapperProps,
        isSelecting,
        selectingValue,
        selectedValue,
      } = useStars(config);
    return (
        <span
              {...getStarWrapperProps({
                style: {
                  cursor: 'pointer',
                  display: 'inline-block',
                  position: 'absolute',
                  textAlign: 'right',
                  width: '100%'
                },
              })}
            >
              {stars?.map((star, i) => (
                <span
                  key={i}
                  {...getStarProps(i, {
                    style: {
                      fontSize: '15px',
                      color: '#9fbf15',
                      padding: '5px',
                      display: 'inline-block'
                    },
                    onClick: (event, ratedValue) => {
                      console.log(`You just rated ${ratedValue} Stars!!`);
                    },
                  })}
                >
                  {star}
                </span>
              ))}
            </span>
    )
}

export default HeartRating
