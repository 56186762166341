import {useState, useEffect} from 'react' 
import { Span } from '../../components/common/Span'
import { QuantityContainer, BoxContainer } from '../../components/common/Container'
import { H2 } from '../../components/common/Heading'  
import { QuantityInput } from '../../components/common/Input'
import { QuantityButton, CartButton, Button } from '../../components/common/Button'
import { BsTrash } from "react-icons/bs"; 
import { Link } from 'react-router-dom'
import shopping from '../../images/noto_shopping-cart.png' 
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store' 

import { getHomeDetails } from '../../network/product'

let default_img = 'https://static.jnb.ph/mommymili/product.jpg'

export default ({cartItems, handleEmptyProduct, updateItem, total, removeItem}) => {  
  const [count, setCount] = useState(0); 

  function handlePlus(key:any) {
    console.log("testkey",cartItems[key].quantity + 1)
    let item = cartItems[key]
    item.quantity = item.quantity + 1;
    updateItem(item) 
  }

  function handleMinus(key:any) {
    console.log("testkey",cartItems[key].quantity - 1)
    let item = cartItems[key]
    item.quantity = item.quantity - 1;
    if(item.quantity <= 0){
      item.quantity = 0;
    } 
    removeItem(item)
  }

  function handleRemoveItem(key:any, e:any) { 
    e.preventDefault(); 
    let item = cartItems[key]
    removeItem(item) 
  }  

  function handleQuantity(e) {
    e.preventDefault();  
    setCount(count)
  }
 
  const market: any = useSelector(
    (state: RootState) => state.cart.market
  ); 
 
  const [data, setData] = useState({} as any);  
  const dispatch = useDispatch<Dispatch>();
  const getAllDetails = async() => { 
    let {details } : any = await getHomeDetails(market.name ? market.name.toLowerCase() : '');

    dispatch.cart.setMarket(details)
    setData(details);   
  }

  useEffect(() => {
    getAllDetails();
  }, []);  

  return(<>  

  {/* Empty Cart */}
  {cartItems.length ===  0 && (
    <div className="tw-m-auto tw-text-center">
      <img src={shopping} className="tw-h-52 tw-mx-auto tw-my-4"/> 
      <div className="tw-my-1">
        <Span size='lg' type='darkgreen' weight='medium'>Empty Shopping Cart</Span>
      </div>
      <Span size='lg' weight='medium'>Looks like you haven't added any item yet</Span>
      <div className='tw-mt-4 tw-text-center'>
        <Link to={'/market/'+(market.name ? market.name.toLowerCase() : '')}>
          <Button  
            type='darkgreen'
            border='green'  
            weight='medium' 
            radius='rounded' 
            width='none' 
            padding='primary'>
              Let's Shop
          </Button> 
        </Link>
      </div> 
    </div>
  )}
      
  
  {cartItems.length !=  0 && (<>
  <div className="lg:tw-w-3/5 md:tw-w-11/12 tw-mb-14"> 
    <table className="grey">
      <thead>
        <tr className="grey">
          <th className="center">
            <Span weight="semibold" transform="uppercase" align="center">Product</Span></th>
          <th className="center">
            <Span weight="semibold" transform="uppercase" align="center">Price</Span></th>
          <th className="center">
            <Span weight="semibold" transform="uppercase" align="center">Quantity</Span></th>
          <th className="center">
            <Span weight="semibold" transform="uppercase" align="center">Subtotal</Span></th>
          <th className="center"> </th>
        </tr>
      </thead>

    {cartItems.map((item, key) => ( 
      <tbody  key={item.id}>  
          <tr className="white">
              <td className="center">
                  <div className="tw-flex tw-my-2">
                      <img src={item.image ? item.image : default_img} alt="" className="cart-image-1"/>
                      <Span weight="semibold" padding="primary" margin="auto" visibility="hidden" align='center'>
                      {item.title} {item.capacity} {item.unit}</Span>
                  </div>
              </td>
              <td className="center">
                  <Span>{`₱${parseFloat(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
              </td>
              <td className="center">
                  <QuantityContainer background="green">
                      <QuantityButton onClick={() => handleMinus(key)} type="none" weight="bold"> - </QuantityButton> 
                      <QuantityInput
                      outline="green" width="eight" color="white"
                      type="text"
                      min="0"
                      value={item.quantity} 
                      onChange={handleQuantity}
                      />
                      <QuantityButton onClick={() => handlePlus(key)} type="none" weight="semibold">+</QuantityButton> 
                  </QuantityContainer> 
              </td>
              <td className="center">
                  <Span>{`₱${parseFloat(item.subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
              </td>
              <td className="center">
                <a href="" onClick={(e) => handleRemoveItem(key, e)} className='tw-m-auto'>
                  <BsTrash className="tw-text-lg tw-text-themegreen-400"/> 
                </a>
              </td>
          </tr> 
      </tbody>
    ))}
    </table>

    <div className="tw-text-right">
      <CartButton textcolor="green" padding="none" justify='end' onClick={handleEmptyProduct}>
        <BsTrash className="card-bag"/>
        Empty Cart
      </CartButton> 
    </div>
  </div>
  
      <BoxContainer width="small" margin="left10" background="lightgrey" border="none">
        <H2>Order Summary</H2>  
          <div className="tw-flex">

            <div className="tw-w-1/2 tw-text-left tw-py-1">
              <Span weight="semibold">Subtotal</Span>
            </div>

            <div className="tw-w-1/2 tw-text-right tw-my-auto">
              <Span weight="semibold">{`₱${parseFloat(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
            </div>

          </div>  

          <div className="tw-flex tw-py-1">

            <div className="tw-w-1/2 tw-text-left">
              <Span weight="semibold">Pabili Fee</Span>
            </div>

            <div className="tw-w-1/2 tw-text-right tw-my-auto">
              <Span weight="semibold">{`₱${parseFloat(data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
            </div>

          </div>  

          <div className="tw-flex tw-border-t tw-border-themegrey-400 tw-py-2 tw-mt-2">

            <div className="tw-w-1/2 tw-text-left tw-my-auto">
              <Span weight="bold">Total</Span>
            </div>

            <div className="tw-w-1/2 tw-text-right">
              <Span weight="bold" type="darkgreen">{`₱${parseFloat(total + data?.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
            </div>

          </div> 

          <Link to="/cod">
              <Button type="green" border="green" padding="primary" transform="uppercase">Proceed to Checkout</Button>
          </Link> 

      </BoxContainer> 
    </>)} 
  </>) 
}