import React, { useEffect, useState} from 'react' 
import { Link } from 'react-router-dom'
import {Span} from '../components/common/Span'
import { IoSearchOutline, IoBag } from "react-icons/io5";
import { MdHome } from "react-icons/md"; 
import CartModal from './Cart/CartModal'
import LocationModal from '../components/LocationModal'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../store'

import getallLogo from '../images/logo.png'  

export default ({ ...props} : any) => {

  const [modalCart, setCartModal] = useState(false); 
  const [modalLocation, setLocationModal] = useState(false);
  const [location, setLocation] = useState(true);
  
  const dispatch = useDispatch<Dispatch>();
  
  const cartItems: any = useSelector(
      (state: RootState) => state.cart.items
  );

  const location_address: any = useSelector(
    (state: RootState) => state.cart.location
  );
  
  const handleLocation = () => {
    // e.preventDefault();
    setLocation(false)
    setLocationModal(false)
  }
 
  const market: any = useSelector(
    (state: RootState) => state.cart.market
  );

  // Cart  
  const [footer, setFooter] = useState(false);  
    
  return (<>  

  <div className="announcement-bar" role="region" aria-label="Announcement">
        <a href="tel: 0915-219-0003"
          className="announcement-bar__link" aria-describedby="a11y-external-message">
          <p className="announcement-bar__message">Contact Us! 📞 0915.219.0003 , 0918.911.1313 , 0917.300.1313</p>
        </a>
        
        <div className="backhome">  
            <a href="https://getall.com.ph/" target="_blank"> 
            <span className="material-icons">home</span> 
            <span className="back">Back to Get All Website</span> 
          </a> 
          
      </div>
    </div>
    <div className="search"> 
      <div className="search-main tw-justify-between">
        <div className="tw-my-auto">
          <Link to={'/market/'+(market.name ? market.name.toLowerCase() : '')}>
            <img src={getallLogo} className="mainlogo" />
          </Link>
        </div>   

        <div className="tw-flex">
          <div className="search-subcontent" onClick={() => {
                  setCartModal(true);
                }} >
            <div className="search-text">
              <div className="relative"> 
                <IoBag className="cart" />
                <span className="cart-count">{cartItems.length}</span>
              </div>
              <a className="text-link">
                <span className="text-cart">Cart</span>
              </a>
            </div>
          </div> 
        </div>
      </div>
 
      <div className="location"> 
      <div className='search-main'>
          <MdHome className='icon-loc'/>
            {
              location_address.address != "" && (<>
                <Span 
                  weight='semibold' 
                  type='lightgreen' 
                  size='base' 
                  padding='primary' 
                  whitespace="nowrap" 
                >
                  Deliver to:
                </Span>
              </>)
            }

          <a  onClick={() => {setLocationModal(true);}} className='tw-my-auto'>
             <Span weight='semibold' type='none' size='base' whitespace="nowrap"> 
               {location_address.address || 'Enter Your Delivery Location'}</Span> 
          </a>
      </div> 
    </div>
    
    {modalCart && <CartModal setCartModal={setCartModal}/>}
    {modalLocation && 
      <LocationModal 
        setLocationModal={setLocationModal} 
        handleLocation={handleLocation} 
      />
    }
    </div>  
  </>);
}
