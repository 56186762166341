import './App.css';
import Home from './pages/'  
import { BrowserRouter, Route, Switch } from 'react-router-dom'; 
import Category from './pages/Category';
import Merchant from './pages/merchant';
import TrackOrder from './pages/trackOrder';
import COD from './pages/cashOnDelivery';
import Payment from './pages/payment';
import PlaceOrder from './pages/placeOrder';
import ViewCart from './pages/viewCart';
import Card  from './pages/card'; 

 
import {useAlert} from 'react-alert'
import moment from 'moment';
import { useEffect } from 'react';
import { RootState } from './store';
import { useSelector } from 'react-redux';



function App() { 
  const alertHook = useAlert();
  
  const alert = useSelector(
    (state: RootState) => state.ui.alert
  );
  
  const { message, type, alertWatcher } = alert
  
  useEffect(() => {
    // checks alert watcher if it is update just two sec ago
    let isLessThanTwoSecs =  moment(alertWatcher).isAfter(moment(moment.now()).subtract(0.5, 'seconds'))
  
    if (message && isLessThanTwoSecs) {
      alertHook.show(message, { type,  timeout: 2000 });
    }
  }, [alertWatcher]);

  return (
    // <Home page={page}/>
    <BrowserRouter>
      <Switch>
      <Route path="/" exact component={Home}/>  
      <Route path="/market/:market" exact component={Home}/>  
      <Route path="/category/:id" exact component={Category} />
      <Route path="/merchant/:id" exact component={Merchant} />
      <Route path="/trackorder" exact component={TrackOrder}/>
      <Route path="/cod" exact component={COD}/>
      <Route path="/card" exact component={Card}/>
      <Route path="/payment" exact component={Payment}/>
      <Route path="/placeorder/:id" exact component={PlaceOrder}/> 
      {/* <Route path="/placeorder" exact component={PlaceOrder}/> */}
      <Route path="/viewcart" exact component={ViewCart}/>
      </Switch>
      </BrowserRouter>
  );
}

export default App;