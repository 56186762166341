import { SubContainer, BannerPageContainer, BoxContainer} from '../../components/common/Container' 
import { H1 } from '../../components/common/Heading'      
import { Span } from '../../components/common/Span'           
import { RiArrowRightSLine } from "react-icons/ri";  
import CartContent from './CartContent'

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'

export default () => {

    const dispatch = useDispatch<Dispatch>();
  
    const cartItems: any = useSelector(
        (state: RootState) => state.cart.items
    );   

    const total: any = useSelector(
        (state: RootState) => state.cart.total
    ); 
    
    const handleEmptyProduct = () => { 
        dispatch.cart.cleanCart(true); 
    }  

    const updateItem = (product) => { 
        dispatch.cart.updateItem(product); 
    }  
    
    const removeItem = (product) => { 
        dispatch.cart.removeItem(product); 
    }

    return (<> 
        <BannerPageContainer background="cart">
            <H1 weight="bold" type="darkgreen">Cart</H1>
            <div className="tw-flex">
                <a href="/" className="darkgreen">Home</a>
                <RiArrowRightSLine className="tw-text-base tw-mt-1 tw-text-themeblack-100"/>
                <Span>Cart</Span>
            </div>
        </BannerPageContainer>

        <SubContainer width="ten">  
            <div className="lg:tw-flex md:tw-flex-none"> 
                <CartContent 
                    cartItems={cartItems}  
                    handleEmptyProduct={handleEmptyProduct} 
                    updateItem={updateItem} 
                    removeItem={removeItem}
                    total={total}
                /> 
            </div> 
        </SubContainer>  
    </>);
}