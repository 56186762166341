import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ModalContainer } from '../../components/common/Container'
import { ModalCloseButton, Button, CartButton } from '../../components/common/Button' 
import { Span } from '../../components/common/Span' 
import { H2 } from '../../components/common/Heading'  
import { IoClose } from "react-icons/io5"; 
import { BsTrash } from "react-icons/bs"; 
import AddItem from "../../components/AddItem"

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'

type Props = {
    setCartModal: Function
  }

export default ({setCartModal,...props}: Props) => {
  
  const dispatch = useDispatch<Dispatch>();
  
  const cartItems: any = useSelector(
      (state: RootState) => state.cart.items
  );  
  
  const total: any = useSelector(
    (state: RootState) => state.cart.total
  );  

  const handleEmptyProduct = () => { 
    dispatch.cart.cleanCart(true); 
  }  

  const updateItem = (product) => { 
    dispatch.cart.updateItem(product); 
  }  
  
  const removeItem = (product) => { 
    dispatch.cart.removeItem(product); 
  }  
  
   
  return (<>   
    <ModalContainer position='end' width='half'>
      <div className="tw-flex tw-p-2 tw-border-b-2 tw-drop-shadow-sm"> 
      <div><H2 weight="bold" margin="center" transform="uppercase">Shopping Cart</H2></div>
      <ModalCloseButton textcolor="green" onClick={() => setCartModal(false)} > 
        <IoClose className="tw-text-2xl"/> 
     </ModalCloseButton>    
    </div>
        
     
    <AddItem 
      cartItems={cartItems}  
      handleEmptyProduct={handleEmptyProduct} 
      updateItem={updateItem} 
      removeItem={removeItem}
      total={total}
      setCartModal={setCartModal}
    />  
     
    </ModalContainer>
  <div className="tw-opacity-60 tw-fixed tw-inset-0 tw-z-40 tw-bg-black"></div>
  </>);
} 